import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Button from '@mui/material/Button';

interface Props {
    forceDisable?: boolean;
    onConfirm(val: string): void;
    placeHolder: string;
    buttonText: string;
}

export function TextFieldButton(props: Props) {
    const [textfieldValue, setTextfieldValue] = useState("")
    const buttonStyle = {
        width: '80%',
        maxWidth: '400px',
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <TextField id="outlined-basic" variant="outlined" placeholder={props.placeHolder} value={textfieldValue} disabled={props.forceDisable ?? false}
                onChange={(e) => setTextfieldValue(e.target.value)} sx={{ ...buttonStyle, backgroundColor: 'white' }} 
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        props.onConfirm(textfieldValue);
                    }
                }}/>
            <Button variant="contained" onClick={() => { props.onConfirm(textfieldValue) }} sx={buttonStyle} 
            disabled={textfieldValue.length == 0 || (props.forceDisable ?? false)}>
                {props.buttonText}
            </Button>
        </Box>
    )
}