import { useState, useRef } from 'react';
import { DeckBuilder } from './DeckBuilder';
import { generatePrompts, generateImage, generateExplanation, generateTTS, generateCategoriesTTS } from '../../logic/deckApi'
import { DeckViewer } from './DeckViewer';
import { CategoryCardProps } from './CategoryCard';
import { GenerationLoadingScreen } from '../../Common/GenerationLoadingScreen';
import mixpanel from 'mixpanel-browser';

enum CurPage {
  DeckBuilder, GeneratingPrompts, DeckViewer
}

async function createCard(prompt: string, correctCategory: string, categories: string[], disableExplanation: boolean, categoryTTS: string): Promise<CategoryCardProps> {
  let image = "";
  let explanation = "PLACEHOLDER";
  let ttsExplanation = "PLACEHOLDER";

  const generateExplanationAndTTS = async () => {
    [image, explanation] = await Promise.all([
      generateImage(prompt),
      generateExplanation(prompt, correctCategory, categories),
    ]);
    ttsExplanation = await generateTTS(explanation);
  };

  if (!disableExplanation) {
    await generateExplanationAndTTS();
  } else {
    image = await generateImage(prompt);
  }

  const cardProps: CategoryCardProps = {
    prompt,
    correctCategory,
    categories,
    imagePath: image,
    categoryTTS: categoryTTS
  };

  if (!disableExplanation) {
    cardProps.explanation = explanation;
    cardProps.ttsExplanation = ttsExplanation;
  }

  return cardProps;
}

export function DeckHome() {
  const [curPage, setCurPage] = useState<CurPage>(CurPage.DeckBuilder);
  const [generatedCards, setGeneratedCards] = useState<CategoryCardProps[]>([]);
  const [generatingInProgress, setGeneratingInProgress] = useState(false);
  const [inExamplesMode, setInExamplesMode] = useState(false);
  const cancelGeneration = useRef(false);


  async function onGenerateDeck(categories: string[], numCards: number, disableExplanation: boolean, examplesMode: boolean) {
    
    setCurPage(CurPage.GeneratingPrompts)
    setGeneratedCards([])
    setInExamplesMode(examplesMode)
    cancelGeneration.current = false;

    const promptAndCats = await generatePrompts(categories, numCards);    
    categories = Array.from(new Set(promptAndCats.map(item => item.category)));    
    mixpanel.track('Deck Generated', { categories, 'cardsPerCategory': numCards, disableExplanation, examplesMode, 'prompts': promptAndCats });
    const catTTSDeck = await generateCategoriesTTS(categories);

    setGeneratingInProgress(true)
    console.log(promptAndCats)

    setTimeout(() => {
      setCurPage(CurPage.DeckViewer)
    }, 30000);

    while (promptAndCats.length && !cancelGeneration.current) {
      const curPrompt = promptAndCats.pop();
      if (curPrompt) {
        try {
          const categoryTTS = catTTSDeck[curPrompt.category];
          const newCard = await createCard(curPrompt.prompt, curPrompt.category, categories, disableExplanation, categoryTTS);
          if (!cancelGeneration.current) {
            setGeneratedCards(prevCards => [...prevCards, newCard]);
          }
        } catch (e) {
          console.error("Card had error with generation. Prompt: " + curPrompt.prompt + " Category: " + curPrompt.category + " Error: " + e);
        }
      }
    }
    if (!cancelGeneration.current) {
      setGeneratingInProgress(false);
    }
  }

  function onFinishedWithDeck() {    
    setCurPage(CurPage.DeckBuilder)
    setGeneratedCards([])
    setGeneratingInProgress(false)
    cancelGeneration.current = true;
  }

  if (curPage === CurPage.GeneratingPrompts) {
    return <GenerationLoadingScreen loadingBarTimeInSeconds={30} loadingText='Generating Deck' />
  }
  else if (curPage === CurPage.DeckViewer) {
    return <DeckViewer cards={generatedCards} inExamplesMode={inExamplesMode}
      isStillGenerating={generatingInProgress} onDone={onFinishedWithDeck} />
  }
  else {
    return <DeckBuilder onGenerateDeck={onGenerateDeck} />
  }
}


