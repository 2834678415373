import { GameState, AppState, RoomStatus, RoundState } from "./commonTypes";

export function getTimeRemaining(gameState: GameState): number {
    const startingTime = gameState.curStateStartTimestamp
    if(!startingTime){
        console.error("No start time")
        return -1
    }

    let additionalTime = 0
    if(gameState.roomStatus == RoomStatus.roundInProgress){
        additionalTime = gameState.roundLengthInSeconds
    }
    else if(gameState.roomStatus == RoomStatus.judging){
        additionalTime = gameState.judgeWaitTimeInSeconds        
        if(additionalTime < 0) return -1
    }
    else if (gameState.roomStatus == RoomStatus.roundOver){
        additionalTime = gameState.roundEndWaitTimeInSeconds
        if(additionalTime < 0) return -1
    }
    else{
        console.error("Invalid room status for getTimeRemaining")
        return -1
    }

    const timeRemaining = Math.round((startingTime + (additionalTime * 1000) - Date.now()) / 1000)
    if(timeRemaining <= 0) return 0
    else return timeRemaining
}

export function getOtherPlayersList(appState: AppState){    
    const curRound = appState.gameState?.curRound
    const prevRound = appState.gameState?.prevRounds?.at(-1)
    
    if(!curRound && !prevRound){
        console.error("No current round")
        return []
    }

    const round = curRound || prevRound

    const allPlayers = round?.players || []
    return allPlayers.filter(player => player.uuid !== appState.myUUID)        
}

export function getMyPrompt(appState: AppState): string{
    const myUUID = appState.myUUID
    
    const curRound = appState.gameState?.curRound
    const prevRound = appState.gameState?.prevRounds?.at(-1)
    if(!curRound && !prevRound){
        console.error("No current round")
        return ""
    }

    const round = (curRound || prevRound) as RoundState

    if(round.judge.uuid === myUUID){
        return round.judge.uuid
    }
    else{
        const myUser = round.players.find(player => player.uuid === myUUID)        
        if(!myUser){
            console.error("No player found with your UUID")
            return ""
        }
        if(myUser.generatedImage?.prompt){
            return myUser.generatedImage.prompt
        }
        else{
            return "No prompt entered"
        }        
    }
}

export function getMyLastGeneratedImage(appState: AppState): string | undefined{
    const myUUID = appState.myUUID
    const myImageInServer = appState.gameState?.curRound?.players?.find((player) => player.uuid === myUUID)?.generatedImage?.url_base64
    return myImageInServer
}