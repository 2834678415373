import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';


interface Props {
    onNext: () => void;
    children?: React.ReactNode
}

export function ErrorPage(props: Props) {
    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', justifyContent: 'center', minHeight: '80vh' }}>
            <Typography variant="h3" textAlign='center'>
                Error :(
            </Typography>
            <Container maxWidth="sm">
                <Typography variant="h5">
                        {props.children}
                </Typography>
            </Container>
            <Button
                variant="contained"
                size="large"
                onClick={() => {
                    props.onNext();
                }}>Try Again
            </Button>
        </Box>
    );
}
