import { useEffect } from 'react'
import {Frame} from '../Frame'
import {CanvasDisplay, CANVAS_STATE} from './Components/CanvasDisplay'

interface Props{
    title: string
    imageSource?: string
    canvasText?: string
}


export function LockedCanvas(props: Props){

    let canvas_state = CANVAS_STATE.ERROR
    if (props.imageSource){
        canvas_state = CANVAS_STATE.SUCCESS
    }
    else if (props.canvasText){
        canvas_state = CANVAS_STATE.INITIAL
    }    

    useEffect(() => {
    }, [props.imageSource])

    return (
        <div>
            <Frame title={props.title}>
                <CanvasDisplay imageSource={props.imageSource} placeholderText={props.canvasText} state={canvas_state}/>
            </Frame>
        </div>
    )
}