import { useEffect, useState, useRef } from "react"
import { useGame } from "../../../logic/useGame"
import { RoomStatus } from "../../../logic/commonTypes"
import { getTimeRemaining} from "../../../logic/GameplayLogic"
import { GameAppBar } from "./GameAppBar"
import { PlayingCanvases } from "./PlayingCanvases"
import { EndGameButtons } from "./EndGameButtons";

export function PlayingPage(): JSX.Element {        
    const [timeRemaining, setTimeRemaining] = useState<number>(-1)       
    const [timerInProgress, setTimerInProgress] = useState(false) 
    const game = useGame() 
    const roomStatus = game?.appState?.gameState?.roomStatus       
    const gameStateRef = useRef(game?.appState?.gameState);

    
    useEffect(() => {
        calculateTimeRemaining()
        const intervalID =  startTimerLoop()
        return () => {
            clearInterval(intervalID)
            setTimerInProgress(false)
        }
    }, [])

    useEffect(() => {
        gameStateRef.current = game?.appState?.gameState
    }, [game?.appState?.gameState])

    useEffect(()=>{
        if(roomStatus == RoomStatus.roundInProgress){
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        }
    }, [game?.appState?.gameState?.roomStatus])
            
    function calculateTimeRemaining() {
        const gameState = gameStateRef.current
        if (! gameState?.roundLengthInSeconds) {
            console.error("roundLengthInSeconds is undefined")
            setTimeRemaining(-1)
            return
        }

        if (! gameState?.curStateStartTimestamp) {
            console.error("roundStartTimestamp is undefined")
            setTimeRemaining(-1)
            return
        }

        const newTimeRemaining = getTimeRemaining(gameState)
        setTimeRemaining(newTimeRemaining)

        if (newTimeRemaining == 0) {
            game.requestStateTransition()
        }
    }

    function startTimerLoop() {
        if (roomStatus == RoomStatus.waitingToStart) {
            console.error("Game is not in progress")
            return
        }
        if(timerInProgress){
            console.error("Timer is already in progress.")
            return 0
        }

        const intervalLength = 1000
        const intervalId = setInterval(() => {
            calculateTimeRemaining()            
        }, intervalLength)

        setTimerInProgress(true)
        return intervalId
    }
    
    return (
        <div>
            <GameAppBar timeRemaining={timeRemaining} />
            {roomStatus == RoomStatus.gameOver && <EndGameButtons/>}            
            <PlayingCanvases />            
        </div>
    )
}