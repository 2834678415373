
export enum APIErrorCodes {
    BAD_API_KEY = "401",
    BAD_PROMPT = "400",
    NO_CREDITS_OR_RATE_LIMIT = "429"
}

function getErrorMessage(errorCode: APIErrorCodes){
    if (errorCode == APIErrorCodes.BAD_API_KEY){
        return "The API key is invalid. Try a new one."
    }
    else if (errorCode == (APIErrorCodes.BAD_PROMPT)) {
        return "The prompt was rejected by the OpenAI Server. Try something else."
    }
    else if (errorCode == (APIErrorCodes.NO_CREDITS_OR_RATE_LIMIT)) {
        return "The key you provided is out of OpenAI API credits OR you've hit the rate limit (try again later)."
    }
    else{
        return "Unknown error. Please contact dev team so we can get to the bottom of this."
    }   
}

export function didError(imageURL: string){
    console.info("Image URL: " + imageURL)
    if (imageURL == null) return false
    if (imageURL.length == 0) return false
    for (let codeValue of Object.values(APIErrorCodes)){
        if (imageURL == getErrorMessage(codeValue))
            return true
    }
    return false
}


export async function generateImage(prompt: string): Promise<string>{
    const endpoint_head = 'https://generateimage-lkjcclx3ma-uc.a.run.app'
    
    const endpoint = endpoint_head + '?prompt=' + encodeURIComponent(prompt)

    try {
        const response = await fetch(endpoint)
        const data = await response.json()
        return data.base64Url
    }
    catch (error: any) {
        console.error("Error generating image: ", error)        
        throw error
    }
}