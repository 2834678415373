import Frame from '../../../Common/Frame';
import { Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom"
import Box from '@mui/material/Box'



interface Props {
    onContinue?: () => void;
}

export function HowToPlay(props: Props) {
    const navigate = useNavigate();

    function handleContinue(){
        if (props.onContinue) {
            props.onContinue();
        }
        else{
            navigate('/')
        }
    }


    return (
       <Box
       sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',        
      }}
      >
       <Container maxWidth='sm'>
            <Frame title="GAME INSTRUCTIONS">
                <Typography variant="h6">1. Get Creative</Typography>
                <Typography paragraph>Craft an image that captivates and amuses.</Typography>

                <Typography variant="h6">2. Gather Your Crew</Typography>
                <Typography paragraph>Share the room code. Kick things off when the gang's all together.</Typography>

                <Typography variant="h6">3. Start the Round</Typography>
                <Typography paragraph>A new player will become the judge. Their image will become the round's creative challenge.</Typography>

                <Typography variant="h6">4. Follow the Vision</Typography>
                <Typography paragraph>The other players must recreate the judge's image as closely as possible, before time expires.</Typography>

                <Typography variant="h6">4. Crown the Champ</Typography>
                <Typography paragraph>When the round ends, the judge will choose a winner.</Typography>

                <Typography variant="h6">5. Rotate and Revel.</Typography>
                <Typography paragraph>The game continues until every player has had a chance to judge.</Typography>

                <Typography paragraph>Let the good times roll! 🎉🎨🚀</Typography>

                <Button variant="contained" color="primary" onClick={handleContinue}>Continue</Button>

            </Frame>
        </Container>
        </Box>
    );
}