import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface FrameProps {
  title: string;
  children?: React.ReactNode;
}

export const Frame: React.FC<FrameProps> = ({ title, children }) => {
  return (
    <Box sx={{
      background: 'white',
      border: '1px solid black',
      boxShadow: '5px 5px 0 0 black',
    }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 5px',
        background: 'black',
        color: 'white',
      }}>
        <Typography variant="subtitle1" sx={{ textTransform: 'uppercase', margin: 0 }}>
          {title}
        </Typography>
      </Box>
      <Box sx={{ padding: '10px' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Frame;
