import React, { useState } from 'react';
import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { Stack, Container } from "@mui/material";


interface Props{
    onComplete: (scenario: string) => void;
}

export function VoicebotSetup(props: Props) {
    const [scenario, setScenario] = useState<string>("");

    return (
        <Container>
            <Stack gap={'20px'} sx={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
                <Typography variant="h4">Social Simulation Voicebot</Typography>
                <>
                    <Typography variant="body1">Describe a social situation you want to simulate, then click create voicebot. This will create a conversational voicebot that you can talk to.<br /><br /> Some examples are Ordering Ice Cream or Calling a Plumber. <br/>If you have trouble with Voicebot, please make sure you are on a fast Wifi connection.</Typography>
                </>

                <TextField
                    id="outlined-basic"
                    label="Write a scenario"
                    variant="outlined"
                    value={scenario} // Bind state variable to TextField
                    onChange={(e) => setScenario(e.target.value)} // Update state on change
                    sx={{ backgroundColor: 'white' }} // Set background color to white
                    fullWidth={true}
                    placeholder='Ordering Ice cream.' multiline={true} minRows={3} />
                <Button variant="contained"
                    disabled={scenario === ""}
                    onClick={() => { props.onComplete(scenario)}}>Create Voicebot</Button>
            </Stack>
        </Container>
    );
}
