import { useGame } from "../../../logic/useGame"
import { RoomStatus, User } from "../../../logic/commonTypes"
import Container from '@mui/material/Container';
import { LockedCanvas } from "../../../Common/Canvas/LockedCanvas";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import { ErrorPage } from "../../../Common/ErrorPage";
import { GenerativeCanvas } from "../../../Common/Canvas/GenerativeCanvas";
import { useState, useEffect } from "react";
import { getMyLastGeneratedImage, getMyPrompt, getOtherPlayersList } from "../../../logic/GameplayLogic";

export function PlayingCanvases() {
    const game = useGame()
    const [serverBackupImage, setServerBackupImage] = useState<string | undefined>('')
    const [canvasResetSignal, setCanvasResetSignal] = useState(false)

    const appState = game?.appState
    const prevRound = appState?.gameState?.prevRounds?.at(-1)
    const curRound = appState?.gameState?.curRound || prevRound
    const roomStatus = appState?.gameState?.roomStatus
    
    const shouldShowPrompt = roomStatus == RoomStatus.roundOver || roomStatus == RoomStatus.judging
    const amIJudge = curRound?.judge.uuid === appState?.myUUID
    const myPrompt = (appState && !amIJudge) ? getMyPrompt(appState) : "INVALID PROMPT (not a player)"
    const shouldRenderPlayableCanvas = !amIJudge && !appState?.inSpectatorMode
    const shouldShowWinnerButtons = (roomStatus == RoomStatus.judging) && amIJudge
    const otherPlayers = appState? getOtherPlayersList(appState) : []

    const onSelectWinner = (winner: User) => {
        game.chooseWinner(winner.uuid)
    }

    useEffect(()=>{
        if (appState) {
            setServerBackupImage(getMyLastGeneratedImage(appState))
        }
    }, [curRound?.players, curRound])

    useEffect(()=>{
        if(roomStatus == RoomStatus.roundInProgress){
            setCanvasResetSignal(true)
            setTimeout(()=>{setCanvasResetSignal(false)}, 100)
        }
    }, [appState?.gameState?.roomStatus])

    if (!curRound) {
        return <ErrorPage onNext={() => { }} >Round not found</ErrorPage>
    }

    return (
        <Container maxWidth='sm'>
            <JudgeCanvas judge={curRound.judge} amIJudge={amIJudge} showPrompt={shouldShowPrompt} />
            {shouldRenderPlayableCanvas && 
            <PlayableCanvas hideInputs={shouldShowPrompt} onImageGenerated={game.submitImage} backupImage={serverBackupImage} 
            showPrompt={shouldShowPrompt} myPrompt={myPrompt} resetSignal={canvasResetSignal}/>}
            <OtherPlayers players={otherPlayers} showPrompts={shouldShowPrompt} showSelectWinnerButtons={shouldShowWinnerButtons}
            onSelectWinner={onSelectWinner}/>
        </Container>
    )    
}

interface JudgeCanvasProps {
    judge: User
    amIJudge: boolean
    showPrompt: boolean
}

function JudgeCanvas(props: JudgeCanvasProps) {
    const myTitle = props.amIJudge ? 'Your Image' : props.judge.displayName + '\'s Image'
    const imageSource = props.judge.generatedImage?.url_base64
    const prompt = props.judge.generatedImage?.prompt

    return (
        <Container maxWidth='sm' sx={{ marginTop: '20px' }}>
            <Typography variant='h5' textAlign={'center'} sx={{ margin: '20px', marginTop: '100px' }}>Recreate This Image:</Typography>
            <LockedCanvas title={myTitle} imageSource={imageSource} />
            {(props.showPrompt) &&
                <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '50px' }}>Prompt: {prompt}</Typography>
            }
        </Container>
    )
}

interface PlayableCanvasProps {
    hideInputs: boolean,
    onImageGenerated: (imageSource: string, imagePrompt: string) => void,
    backupImage: string | undefined,
    showPrompt: boolean,
    myPrompt: string,
    resetSignal: boolean
}
function PlayableCanvas(props: PlayableCanvasProps) {
    return (
        <Box sx={{ margin: '20px' }}>
            <Typography variant='h5' textAlign={'center'} marginBottom={'20px'} marginTop='50px'>Your Canvas</Typography>
            <GenerativeCanvas hideInputs={props.hideInputs} onImageGenerated={props.onImageGenerated}
                title={'Your Image'} backupImage={props.backupImage} resetSignal={props.resetSignal}/>
            {(props.showPrompt) &&
                <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '50px' }}>Prompt: {props.myPrompt}</Typography>
            }
        </Box>
    )
}

interface OtherPlayersProps{
    players: User[]
    showPrompts: boolean
    showSelectWinnerButtons: boolean
    onSelectWinner: (winner: User) => void    
}
function OtherPlayers(props: OtherPlayersProps) {        
    const [winnerButtonDisabled, setSelectWinnerButtonDisabled] = useState(false)
    
    useEffect(()=>{
        setSelectWinnerButtonDisabled(false)
    }, [props.showSelectWinnerButtons])

    return(
        <Container>
            <Typography variant='h5' textAlign={'center'} sx={{ marginBottom: '20px', marginTop: '50px' }} >Other Players</Typography>
            {props.players.map((player) => (
                <Container key={player.uuid} maxWidth='sm' sx={{ marginTop: '50px' }}>
                    <LockedCanvas title={player.displayName} imageSource={player.generatedImage?.url_base64} canvasText={player.displayName + ' is typing...'} />
                    {props.showPrompts && <Typography variant='h6' textAlign={'center'} marginTop='20px'>Prompt: {player.generatedImage?.prompt || "No prompt entered."}</Typography>}
                    {props.showSelectWinnerButtons && <Box sx={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
                        <Button variant='contained' disabled={winnerButtonDisabled} onClick={() => {props.onSelectWinner(player); setSelectWinnerButtonDisabled(true)}}>Select as Winner</Button>
                    </Box>}
                </Container>
            ))}            
        </Container>
    )    
}