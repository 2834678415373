import { VoicebotSetup } from './VoicebotSetup';
import { useState } from 'react';
import { VoicebotPlayer } from './VoicebotPlayer';
import mixpanel from 'mixpanel-browser';

export function Voicebot(){
    enum State{
        SETUP,        
        RUNNING    
    }

    const [state, setState] = useState(State.SETUP);
    const [scenario, setScenario] = useState<string>("");

 
    async function onSetupComplete(scenario: string){
        mixpanel.track("StartVoicebot", {scenario})        
        setScenario(scenario);        
        setState(State.RUNNING);        
    }

     if(state === State.SETUP){
        return <VoicebotSetup onComplete={onSetupComplete}/>
    }
    else if(state === State.RUNNING){
        return <VoicebotPlayer onComplete={() => setState(State.SETUP)} scenario={scenario}/>
    }
    else{
        return <div>Invalid state</div>
    }

}

