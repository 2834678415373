import TextField from '@mui/material/TextField';
import { Stack, Typography } from '@mui/material';
import { useState } from 'react';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';

interface CategoryFieldProps {
    index: number;
    category: string;
    onDelete: (index: number) => void;
}

function CategoryField(props: CategoryFieldProps) {
    return (
        <Stack sx={{ marginTop: '40px' }} direction={'row'}>
            <TextField
                value={props.category}
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    '& .Mui-disabled': {
                        WebkitTextFillColor: 'black', // For WebKit browsers
                        color: 'black', // For other browsers
                    },
                }}
                disabled={true}
                InputProps={{
                    sx: {
                        color: 'black',
                        '&.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                        },
                    },
                }}
            />
            <Button variant={'outlined'} onClick={() => props.onDelete(props.index)}>Delete</Button>
        </Stack>
    );
}

interface DeckBuilderProps {
    onGenerateDeck: (categories: string[], numCards: number, disableExplanation: boolean, examplesMode: boolean) => void;
}

export function DeckBuilder(props: DeckBuilderProps) {
    const [newCategory, setNewCategory] = useState<string>("");
    const [createdCategories, setCreatedCategories] = useState<string[]>([]);

    const [disableExplanation, setDisableExplanation] = useState<boolean>(false);
    const [examplesMode, setExamplesMode] = useState<boolean>(false);

    const numCards = 5

    const addCategory = () => {
        if (newCategory.trim() !== "") {
            const categoryToAdd = newCategory.trim().replaceAll(',', '')

            setCreatedCategories([...createdCategories, categoryToAdd]);
            setNewCategory("");
        }
    };

    const deleteCategory = (index: number) => {
        const newCategoryFields = createdCategories.filter((_, i) => i !== index);
        setCreatedCategories(newCategoryFields);
    };

    return (
        <Container maxWidth='md' sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack spacing={4} width={'100%'} sx={{ padding: '20px' }}>
                <Typography variant='h4'>Deck Builder</Typography>
                <Typography variant='h6'>To create a deck, add at least two categories. This deck will then use image-based flashcards to test your ability to identify the correct category.</Typography>
                <Stack sx={{ marginTop: '40px' }} direction={'row'}>
                    <TextField
                        label="Enter New Category"
                        value={newCategory}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setNewCategory(event.target.value);
                        }}
                        sx={{ backgroundColor: 'white', width: '100%' }}
                    />
                    <Button onClick={addCategory} variant='contained'>Add</Button>
                </Stack>


                {createdCategories.map((cat, index) => (
                    <CategoryField
                        key={index}
                        index={index}
                        category={cat}
                        onDelete={deleteCategory}
                    />
                ))}

                <Stack spacing={2} direction={'row'}>
                    <Switch
                        checked={disableExplanation}
                        onChange={(event, checked) => setDisableExplanation(checked)}                        
                    />
                    <Typography variant='h6'>Disable Explanation</Typography>
                </Stack>

                <Stack spacing={2} direction={'row'}>
                    <Switch
                        checked={examplesMode}
                        onChange={(event, checked) => setExamplesMode(checked)}                        
                    />
                    <Typography variant='h6'>Examples Mode</Typography>
                </Stack>

                <Button variant="contained" color="primary"
                    disabled={createdCategories.length < 2} onClick={() => props.onGenerateDeck(createdCategories, numCards, disableExplanation, examplesMode)}>
                    Create Deck
                </Button>
            </Stack>
        </Container>
    );
}
