import OpenAI from "openai";
import * as fal from "@fal-ai/serverless-client";
fal.config({
    credentials: '60c0e1ce-bc67-4e68-868a-358ad9d24a59:a43c0d5ff485b6ca99bc23e3adc7cde5',
});


const openai = new OpenAI({ apiKey: 'sk-proj-jgicUghEZ418qcx0P9dfT3BlbkFJJl92D5P2LkDfduQkQxHb', dangerouslyAllowBrowser: true }); //TODO


export type promptCatPair = {
    category: string,
    prompt: string
}

function shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}

export async function generatePrompts(categories: string[], numCards: number): Promise<promptCatPair[]> {    
    categories = categories.map(category=>{
        return category.split(',').join('').trim()
    })

    const llmPrompt = 'Give me ' + numCards + ' image generation prompts that represent each of these categories. You should return a category, then an array of prompts and nothing else. Return it in a JSON format. Here are the categories: ' + categories.join(', ');

    const response = await openai.chat.completions.create({
        messages: [{ role: "user", content: llmPrompt }],
        model: "gpt-4o",
        response_format: {
            type: 'json_object'
        }
    });

    let generatedPrompts = response.choices[0].message.content;
    console.log("Generated prompts: " + generatedPrompts)
    if (!generatedPrompts) {
        return [];
    }

    const generatedPromptJSON = JSON.parse(generatedPrompts) as any;
    if (!generatedPrompts) {
        return [];
    }

    const pairs: promptCatPair[] = [];
    
    Object.keys(generatedPromptJSON).forEach(category => {
        generatedPromptJSON[category].forEach((prompt: string) => {
            pairs.push({ category, prompt });
        });
    });

    console.log("Generated pairs: ", pairs)

    return shuffleArray(pairs)
}


export async function generateImage_segmind(prompt: string): Promise<string> { //This runs into API rate limits really quickly
    console.log("*****Generating image for prompt: " + prompt);

    const endpoint_head = 'https://generateimage-lkjcclx3ma-uc.a.run.app'

    const endpoint = endpoint_head + '?prompt=' + encodeURIComponent(prompt)

    try {
        const response = await fetch(endpoint)
        const data = await response.json()
        const base64 = data.base64Url
        console.log("Generated image for prompt: " + prompt + " url: " + atob(base64))
        return atob(base64)
    }
    catch (error: any) {
        console.error("Error generating image: ", error)
        throw error
    }
}

export async function generateImage_dalle(prompt: string): Promise<string> {
    console.log("*****Generating image for prompt: " + prompt);
    const response = await openai.images.generate({
        model: "dall-e-3",
        prompt: prompt,
        n: 1,
        size: "1024x1024",
    });
    console.log("Dalle3 response: ")
    console.log(response)
    return response.data[0].url || "ERROR: No image generated.";
}

export async function generateImage(prompt: string): Promise<string> {
    console.log("***Generating via Fal.ai")
    const output: any = await fal.subscribe("fal-ai/stable-cascade", {
        input: {
            prompt: prompt
        },
        logs: true
    });

    return output.images[0].url || "ERROR: No image generated.";
}

export async function generateExplanation(prompt: string, category: string, categories: string[]): Promise<string> {
    console.log("Getting explanation for prompt: " + prompt);

    const llmPrompt = 'Explain to a child why this image is in the category ' + category + '. The categories are: ' + categories.join(', ') + '. The prompt for the image is: ' + prompt;

    const response = await openai.chat.completions.create({
        messages: [{ role: "user", content: llmPrompt }],
        model: "gpt-4o",
    });

    let explanation = response.choices[0].message.content;
    console.log("Generated explanation: " + explanation)

    return explanation || "ERROR: No explanation generated.";
}

export async function generateTTS(text: string): Promise<string> {
    console.log("*****Generating TTS for text: " + text);

    // Generate the TTS audio
    const mp3 = await openai.audio.speech.create({
        model: "tts-1",
        voice: "alloy",
        input: text,
    });

    console.log(mp3);

    // Convert the response to a blob
    const arrayBuffer = await mp3.arrayBuffer();
    const blob = new Blob([new Uint8Array(arrayBuffer)], { type: 'audio/mpeg' });

    // Create a URL for the blob
    const audioURL = URL.createObjectURL(blob);

    return audioURL;
};


export async function generateCategoriesTTS(categories: string[]): Promise<{ [key: string]: string }> {
    console.log("Generate CategoriesTTS. Categories: " + categories.join(", "));
    
    const ttsPromises = categories.map(category => generateTTS("This is " + category).then(url => ({ [category]: url })));
    const ttsResults = await Promise.all(ttsPromises);
    const ttsDictionary = ttsResults.reduce((acc, curr) => ({ ...acc, ...curr }), {});

    return ttsDictionary;
}