import OpenAI from "openai";
const openai = new OpenAI({ apiKey: 'sk-proj-jgicUghEZ418qcx0P9dfT3BlbkFJJl92D5P2LkDfduQkQxHb', dangerouslyAllowBrowser: true }); //TODO


export async function gradeAudio(userAudio: Blob, actualText: string) {
    console.log("gradeAudio. User audio: ", userAudio)
    
    const transcript = await transcribeAudio(userAudio);
    const wordsMissed = getNumberOfMissingWords(actualText, transcript);
    const wordComparisonJSON = getWordComparisonJSON(actualText, transcript);

    return { wordsMissed, wordComparisonJSON, transcript }

}

async function transcribeAudio(audio: Blob): Promise<string> {
    const file = new File([audio], "audio.mp3", { type: audio.type });

    const transcription = await openai.audio.transcriptions.create({
        file: file,
        model: "whisper-1",
    });

    console.log(transcription.text);

    return transcription.text;
}

function cleanText(text: string): string {
    return text.toLowerCase().replace(/[^\w\s]/gi, '');
}

function getNumberOfMissingWords(actualText: string, userText: string) {
    
    const actualWords = cleanText(actualText).split(' ');
    const userWords = cleanText(userText).split(' ');

    let missingWords = 0;

    actualWords.forEach(word => {
        if (!userWords.includes(word)) {
            missingWords++;
        }
    });

    console.log("Missing words: ", missingWords)

    return missingWords;
}

export type WordComparison = {
    word: string;
    correct: boolean;
};

function getWordComparisonJSON(actualText: string, userText: string): WordComparison[] {
    const actualWords = cleanText(actualText).split(' ');
    const userWords = cleanText(userText).split(' ');

    const comparison = userWords.map(word => ({
        word: word,
        correct: actualWords.includes(word)
    }));

    console.log("Comparison: ", comparison)

    return comparison;
}