
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Frame from '../../Common/Frame';
import CanvasDisplay, { CANVAS_STATE } from '../../Common/Canvas/Components/CanvasDisplay';
import { Box, Button, TextField } from '@mui/material';
import { useState } from 'react';
import { generateImage_dalle } from '../../logic/deckApi';
import mixpanel from 'mixpanel-browser';

export function SocialScenes() {
    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <Container sx={{marginBottom: '20px'}}>
                <Typography variant='h3' textAlign={'center'}>Scene Maker</Typography>
                <Typography variant='h6' textAlign={'center'}>Describe a scene you'd like an image of (e.g. a happy boy smiling in a dentist's chair), then hit create. If you are unhappy with the result (e.g. if it's cartoon instead of realistic), you can try pressing 'create' again.</Typography>
            </Container>
            <GenerativeCanvas />
        </Container>
    )
}

function GenerativeCanvas() {
    const [imagePath, setImagePath] = useState('')
    const [generationState, setGenerationState] = useState(CANVAS_STATE.INITIAL)
    const [errorText, setErrorText] = useState('')

    function onGenerateClicked(prompt: string) {
        mixpanel.track('Scene Generated', { prompt: prompt });
        setGenerationState(CANVAS_STATE.GENERATING)

        generateImage_dalle(prompt).then((response) => {
            setImagePath(response)
            setGenerationState(CANVAS_STATE.SUCCESS)
            setErrorText('')
        }).catch((error) => {
            mixpanel.track('Scene Generation Error', { prompt: prompt, error: error.message });
            setGenerationState(CANVAS_STATE.ERROR)
            console.error("Error:\n", error)
            console.error("Type: " + typeof (error))
            const errorMessage = error.message
            setErrorText(errorMessage)
        })
    }


    return (
        <Container sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '50px'
        }}>
            <Frame title='Describe a scene'>
                <CanvasDisplay state={generationState} imageIsNotEncoded={true} placeholderText={'Visualize a social scene'}
                bigCanvas={true} imageSource={imagePath} errorText={errorText} fixedSize={512} />
                <GenInputs onGenerateClicked={(prompt) => onGenerateClicked(prompt)} stillGenerating={generationState == CANVAS_STATE.GENERATING} />
            </Frame>
        </Container>
    )
}

function GenInputs(props: { onGenerateClicked: (prompt: string) => void, stillGenerating: boolean}) {
    const [prompt, setPrompt] = useState('')

    const createButtonDisabled = prompt.length == 0 || props.stillGenerating
    return (
        <Box sx={{ gap: '10px', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <TextField id="outlined-basic" label="Describe the scene" variant="outlined"
                value={prompt} onChange={(e) => setPrompt(e.target.value)} fullWidth={true}
                placeholder='An upset kid spilling his cup of water at the lunch table.' multiline={true} minRows={3} />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button disabled={createButtonDisabled} variant={'contained'} size='large'
                    onClick={() => { props.onGenerateClicked(prompt) }}>Create</Button>
            </Box>
        </Box>
    )
}