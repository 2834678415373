import React from 'react';
import { Button, Container } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth0 } from '@auth0/auth0-react';

const LogoutButton: React.FC = () => {
  const { logout, isAuthenticated } = useAuth0();

  function onLogout(){
    logout({ logoutParams: { returnTo: window.location.origin } })
  }

  if (!isAuthenticated) return <></>;

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginTop: '500px' }}>
      <Button
        variant="contained"
        sx={{
          backgroundColor: '#4285F4',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#357ae8',
          },
          display: 'flex',
          alignItems: 'center',
          padding: '8px 16px',
          textTransform: 'none',
        }}
        startIcon={<LogoutIcon />}
        onClick={onLogout}
      >
        Logout
      </Button>
    </Container>
  );
};

export { LogoutButton };
