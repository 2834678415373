import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router";
import { useState } from 'react';


export function EndGameButtons() {
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);        
    const navigate = useNavigate()
    
    function onPlayAgain() {
        navigate('/')
        window.location.reload()
    }
    
    function copyLink(){
        if (navigator.share) {
            navigator.share({
                title: 'Bits&Bots',
                text: 'Check out my game of Bits&Bots!',
                url: window.location.href
            })
            return
        }
        else {
            console.error("navigator.share is undefined")
        }

        if (!navigator.clipboard || !navigator.clipboard.writeText) {
            console.error("Clipboard API not available")
            return
        }

        navigator.clipboard.writeText(window.location.href);
        setSnackbarOpen(true)
    }

    return (
        <Container maxWidth='sm' sx={{ marginTop: '50px', marginBottom: '50px', display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={copyLink}>Share Results</Button>
            </Box>
            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}>
                <Button variant='contained' onClick={() => { onPlayAgain() }}>Play Again</Button>
            </Box>

            <Snackbar open={isSnackbarOpen} autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)} message={'Link Copied!'} />
        </Container>
    )
}