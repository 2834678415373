import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useState } from 'react';

import { Box } from '@mui/material';
import { Stack } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CategoryCardProps, CategoryCard } from './CategoryCard';
import mixpanel from 'mixpanel-browser';

interface DeckViewerProps {
    cards: CategoryCardProps[];
    isStillGenerating: boolean;
    onDone: () => void;
    inExamplesMode: boolean;
}

export function DeckViewer(props: DeckViewerProps) {
    const [curCardIndex, setCurCardIndex] = useState(0);

    function onNextCard() {
        setCurCardIndex(prevIndex => prevIndex + 1);        
        window.scrollTo(0, 0);
    }

    function onDone(){        
        mixpanel.track('Deck Completed', { 'curCardNum': curCardIndex + 1 });
        props.onDone();
    }

    function onRestart() {
        setCurCardIndex(0);        
        mixpanel.track('Deck Repeated');
    }

    if (props.isStillGenerating && curCardIndex >= props.cards.length) {
        return <GeneratingCardPage />
    }
    else if (curCardIndex >= props.cards.length) {
        return <EndOfDeckPage onRestart={onRestart} onDone={onDone} />
    }

    const curCard = props.cards[curCardIndex];
    return (
        <Box>
            <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                <Button variant='outlined' onClick={onDone}>Exit</Button>
            </Box>
            
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                marginTop={2}
                marginBottom={5}
            >

                <CategoryCard {...curCard} onNextCard={onNextCard} key={curCardIndex} inExamplesMode={props.inExamplesMode} />
            </Box>
        </Box>
    )
}

function GeneratingCardPage() {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
        >

            <Stack spacing={2} alignItems="center" width="80%">
                <CircularProgress />
                <Typography variant="h6">Generating Next Card</Typography>
            </Stack>
        </Box>
    );
}

function EndOfDeckPage(props: { onRestart: () => void, onDone: () => void }) {
    return (
        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            marginTop={10}
        >
            <Stack spacing={2} alignItems="center" width="80%">
                <Typography variant="h6">End of Deck</Typography>
                <Button onClick={props.onRestart} variant={'contained'}>Repeat</Button>
                <Button onClick={props.onDone} variant={'contained'}>Done</Button>
            </Stack>
        </Box>
    );
}