import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: {
      main: '#000000',
    },
    action: {
      disabledBackground: '#8c8c8c'
    }
  },
  typography: {
    fontFamily: '"IBM Plex Mono", monospace',
    button: {
      textTransform: 'uppercase',
      fontSize: '1rem'
    },    
  },
  shape: {
    borderRadius: 0
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            opacity: 1.0,
          },
        },
      },
    },
  },
});