// useAudioRecorder.ts
import { useState, useRef } from 'react';
import RecordRTC, { Options } from 'recordrtc';

export function useAudioRecorder(onRecordingComplete: (audioBlob: Blob) => void) {
    const [isRecording, setIsRecording] = useState(false);
    const recorderRef = useRef<any>(null);
    const audioStreamRef = useRef<MediaStream | null>(null);

    async function startRecording(callback?: () => void) {
        if (isRecording) {
            stopRecording();
            return;
        }

        try {
            setIsRecording(true);
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            audioStreamRef.current = stream;

            const options: Options = {
                type: 'audio',
                mimeType: 'audio/wav',
                disableLogs: true,
                bufferSize: 16384,
                sampleRate: 44100,
                numberOfAudioChannels: 2,
                recorderType: RecordRTC.StereoAudioRecorder,
            };

            const browserIsSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            if (browserIsSafari) {
                options.recorderType = RecordRTC.StereoAudioRecorder;
            }

            const recorder = new RecordRTC(stream, options);
            recorder.startRecording();
            recorderRef.current = recorder;

            if (callback) {
                callback();
            }
        } catch (error) {
            console.error("Error accessing audio device:", error);
            setIsRecording(false);
        }
    }

    function stopRecording() {
        if (recorderRef.current) {
            recorderRef.current.stopRecording(() => {
                const audioBlob = recorderRef.current.getBlob();
                onRecordingComplete(audioBlob);

                recorderRef.current.destroy();
                recorderRef.current = null;

                if (audioStreamRef.current) {
                    audioStreamRef.current.getTracks().forEach((track) => track.stop());
                    audioStreamRef.current = null;
                }

                setIsRecording(false);
            });
        }
    }

    return {
        isRecording,
        startRecording,
        stopRecording,
    };
}
