import {AppBar, Typography} from "@mui/material"
import {GameState, AppState, RoomStatus} from "../../../logic/commonTypes"
import { useGame } from "../../../logic/useGame"

interface Props{
    timeRemaining: number    
}

function getAppBarText(appState: AppState, timeRemaining: number): string{    
    const gameState = appState.gameState
    if(!gameState){
        console.error("No game state")
        return ""
    }
    
    if (gameState.roomStatus == RoomStatus.waitingToStart){
        console.error("Shouldn't be in this state")
        return ""
    }
    else if(gameState.roomStatus == RoomStatus.roundInProgress){
        return "Time Remaining: " + timeRemaining + " seconds"
    }
    else if (gameState.roomStatus == RoomStatus.judging){
        const judge = gameState.curRound?.judge
        if(!judge){
            console.error("No judge")
            return ""
        }
        const amIJudge = judge.uuid === appState.myUUID
        if(amIJudge){
            const trailingVal = timeRemaining > -1 ? " You have " + timeRemaining + " seconds" : ""
            return "Round over. Pick a winner!" + trailingVal
        }
        else{
            return judge.displayName + ' is picking a winner'
        }                
    }
    else if(gameState.roomStatus == RoomStatus.roundOver){
        const prevRounds = gameState.prevRounds
        if(!prevRounds){
            console.error("No Prev rounds found in roundOver state")
            return "Round Over Error State"
        }
        if(!prevRounds.length){
            console.error("Prev rounds length is 0")
            return "Round Over Error state"
        }

        const lastRound = prevRounds[prevRounds.length-1]
        const winnerName = lastRound.winner?.displayName
        const trailingVal = timeRemaining > -1 ? " Starting the next round in " + timeRemaining + " seconds" : ""
        if(winnerName)
            return winnerName + ' won the round!' + trailingVal
        else
            return "No winner was chosen." + trailingVal
    }
    else if(gameState.roomStatus == RoomStatus.gameOver){
        return "Game Over"
    }
    else{
        console.error("Invalid room status")
        return ""
    }
}


export function GameAppBar(props: Props){
    const game = useGame()
    const appState = game?.appState
    if(!appState){
        console.error("No app state")        
    }

    const appBarText = appState ? getAppBarText(appState, props.timeRemaining) : "Calculating..."
    
    return (
    <AppBar position="static">
        <Typography variant='h5' textAlign={'center'}>{appBarText}</Typography>
    </AppBar>
    )
}
