export function BackgroundImage(){
    return (
        <>
        <img 
            src='/background/dotted_grid.png' 
            style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                objectPosition: 'center',
                zIndex: -1
            }}
        />
        </>
    )
}