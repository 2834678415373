import React, { useRef, useState } from "react";
import { Typography, Button, Stack, Container, Box, LinearProgress, Divider } from "@mui/material";
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import { useAudioRecorder } from "../../../logic/useAudioRecorder";
import mixpanel from "mixpanel-browser";

interface AudioPlayerProps {
    lineNumber: number;    
    onRecordingComplete: (audio: Blob) => void;
    audioSrc: string;
}

export function AudioPlayer(props: AudioPlayerProps){
    const { isRecording, startRecording, stopRecording } = useAudioRecorder(props.onRecordingComplete);
    const RECORDING_AUTO_STOP_SECONDS = 10;
    const recordingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [isPlaying, setIsPlaying] = useState<boolean>(false);

    function onRecordPressed() {
        if (isRecording) {
            stopRecording();
            if (recordingTimeoutRef.current) {
                clearTimeout(recordingTimeoutRef.current);
            }
        } else {
            startRecording(() => {
                recordingTimeoutRef.current = setTimeout(() => {
                    stopRecording();
                    console.log("Recording Timed out");
                }, RECORDING_AUTO_STOP_SECONDS * 1000);
            });
        }
    }

    async function onPlayPressed() {
        if (isRecording) {
            console.error("Cannot play while recording");
            return;
        }

        if (isPlaying) {
            stopPlayingAudio();
        } else {
            playAudio();
        }
    }

    async function playAudio() {
        setIsPlaying(true);
        mixpanel.track('Play Audio', { lineNumber: props.lineNumber + 1 });

        const audio = new Audio(props.audioSrc);
        audioRef.current = audio;

        audio.play().then(() => {
            console.log('Audio playback started');
        }).catch((error) => {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
        });

        audio.onended = () => {
            stopPlayingAudio();
        };
    }

    function stopPlayingAudio() {
        setIsPlaying(false);

        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
    }

    return (
        <Container maxWidth={'md'}>
            <Box display="flex" flexDirection="column" alignItems="center" gap={'20px'}>
                <Typography variant="h3" textAlign={'center'}>Audio Player</Typography>
                <Typography variant="body1">Play the audio until you understand it. Make sure you understand EVERY word in the sentence. When you are ready to repeat them, click Start Recording. When you're done, click Stop Recording.</Typography>
                <Typography variant="body1">Line Number: {props.lineNumber + 1}</Typography>
                <Stack gap={'100px'} marginTop={'50px'} maxWidth={'sm'} alignItems={'center'}>
                    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={onPlayPressed}
                            sx={{
                                width: '150px',
                                height: '150px',
                                borderRadius: '50%',
                                fontSize: '24px',
                                backgroundColor: 'black',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                            disabled={isRecording}
                        >
                            {isPlaying ? <StopIcon sx={{ fontSize: 60 }} /> : <PlayArrowIcon sx={{ fontSize: 60 }} />}
                        </Button>
                        <Typography variant="h6" marginTop="10px">
                            {isPlaying ? 'Stop Audio' : 'Play Audio'}
                        </Typography>
                    </Container>
                    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Button
                            variant="contained"
                            onClick={onRecordPressed}
                            sx={{
                                width: '150px',
                                height: '150px',
                                borderRadius: '50%',
                                fontSize: '16px',
                                backgroundColor: isRecording ? 'red' : 'black',
                                '&:hover': {
                                    backgroundColor: isRecording ? 'red' : 'black',
                                },
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                            disabled={isPlaying}
                        >
                            <MicIcon sx={{ fontSize: 60, color: 'white' }} />
                        </Button>
                        <Typography variant="h6" marginTop="10px">
                            {isRecording ? 'Stop Recording' : 'Start Recording'}
                        </Typography>
                    </Container>
                </Stack>                
            </Box>
        </Container>
    );
}
