import { Frame } from '../Frame'
import { CANVAS_STATE, CanvasDisplay } from './Components/CanvasDisplay'
import { useState } from 'react'
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { generateImage } from '../../logic/imageGenerator'
import Container from '@mui/material/Container';

interface Props {
    onNextClicked?: (imageSource: string, imagePrompt: string) => void
    hideInputs?: boolean
    onImageGenerated?: (imageSource: string, imagePrompt: string) => void
    title?: string
    backupImage?: string,
    resetSignal?: boolean
}

interface GenInputsProps {
    onNextClicked?: () => void | undefined
    onGenerateClicked: (prompt: string) => void
    disableNext?: boolean
    disableGenerate?: boolean
    resetSignal? : boolean
}

function GenInputs(props: GenInputsProps) {
    const [prompt, setPrompt] = useState('')

    useEffect(()=>{
        if(props.resetSignal){
            setPrompt('')
        }
    }, [props.resetSignal])

    const createButtonDisabled = props.disableGenerate || prompt.length == 0
    return (
        <Box sx={{gap: '10px', display: 'flex', flexDirection: 'column'}}>
            <TextField id="outlined-basic" label="Enter Your Prompt" variant="outlined"
                value={prompt} onChange={(e) => setPrompt(e.target.value)} fullWidth = {true} 
                placeholder='Lightning in a bottle, Van Gogh' multiline={true} minRows={3}/>
            <Box sx={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                <Button disabled={createButtonDisabled} variant={'contained'} size='large'
                onClick={() => { props.onGenerateClicked(prompt) }}>Create</Button>
                {props.onNextClicked != undefined &&
                    <Button disabled={props.disableNext} onClick={props.onNextClicked} variant={'contained'} size='large'>Continue</Button>
                }
            </Box>
        </Box>
    )
}


export function GenerativeCanvas(props: Props) {
    const [imagePath, setImagePath] = useState('')
    const [prompt, setPrompt] = useState('') 
    const [generationState, setGenerationState] = useState(CANVAS_STATE.INITIAL)
    const [errorText, setErrorText] = useState('')

    const shouldUseBackupImage = props.backupImage != undefined && imagePath.length == 0

    function onNextClicked() {
        if(!props.onNextClicked){
            console.error("onNextClicked not defined")
            return
        }
        props.onNextClicked(imagePath, prompt)        
    }

    function onGenerateClicked(prompt: string) {
        setGenerationState(CANVAS_STATE.GENERATING)
        setPrompt(prompt)
        generateImage(prompt).then((response) => {
            setImagePath(response)
            setGenerationState(CANVAS_STATE.SUCCESS)
            setErrorText('')
            if(props.onImageGenerated){
                props.onImageGenerated(response, prompt)
            }
        }).catch((error) => {
            setGenerationState(CANVAS_STATE.ERROR)
            console.error("Error:\n", error)
            console.error("Type: " + typeof (error))
            const errorMessage = error.message
            setErrorText(errorMessage)
        })
    }

    useEffect(()=>{
        if(props.resetSignal){
            setImagePath('')
            setPrompt('')
            setGenerationState(CANVAS_STATE.INITIAL)
            setErrorText('')
        }
    }, [props.resetSignal])

    return (
        <Container maxWidth='sm'>
            <Frame title={props.title ?? 'Generative Canvas'}>
                <Box sx={{gap: '20px', display: 'flex', flexDirection: 'column'}}>
                    <CanvasDisplay imageSource={shouldUseBackupImage ? props.backupImage: imagePath} 
                    state={shouldUseBackupImage ? CANVAS_STATE.SUCCESS : generationState} errorText={errorText} />
                    {!(props.hideInputs ?? false) &&
                        <Box sx={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <GenInputs onNextClicked={props.onNextClicked ? onNextClicked : undefined} onGenerateClicked={onGenerateClicked}
                                disableNext={generationState != CANVAS_STATE.SUCCESS}
                                disableGenerate={generationState == CANVAS_STATE.GENERATING} />
                        </Box>
                    }
                </Box>
            </Frame>
        </Container>
    );
}