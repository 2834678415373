import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useGame } from '../../../logic/useGame';
import { Typography } from '@mui/material';
import { RoundState, User } from '../../../logic/commonTypes';
import { LockedCanvas } from '../../../Common/Canvas/LockedCanvas';
import Container from '@mui/material/Container';
import { EndGameButtons } from './EndGameButtons';
import { useParams, useNavigate } from 'react-router-dom';

export function GameOverPage() {  
  const game = useGame();
  const navigate = useNavigate();
  const parsedParams = useParams<{ roundNumber: string }>();

  let { roomId, roundNumber } = useParams();

  const roundNumberInt = parseInt(parsedParams.roundNumber || '1');

  const numberOfRounds = game.appState?.gameState?.prevRounds?.length || 0;
  const selectedRound = game.appState?.gameState?.prevRounds?.at(roundNumberInt - 1);

  if (!selectedRound) {
    console.error('Selected round not found');
    return <Typography>Selected round not found</Typography>
  }  

  const onRoundSelected = (roundNumber: number) => {
    navigate(`/game/${roomId}/${roundNumber}`);
  }  

  return (
    <div>
      <RoundSelectButtons numberOfRounds={numberOfRounds} onRoundSelected={onRoundSelected} initialRound={roundNumberInt}/>
      <RoundCanvases round={selectedRound} />
      <EndGameButtons />
    </div>
  )
}

interface SingleCanvasProps {
  user: User;
}

function SingleCanvas(props: SingleCanvasProps) {

  const title = props.user.displayName.trim() + "'s Canvas";
  return (
    <Container maxWidth='sm' sx={{ marginTop: '20px' }}>
      <LockedCanvas title={title} imageSource={props.user.generatedImage?.url_base64} />
      <Typography variant='h6' sx={{ marginTop: '20px', marginBottom: '50px' }}>Prompt: {props.user.generatedImage?.prompt || "No prompt entered"}</Typography>
    </Container>
  )
}


interface RoundCanvasesProps {
  round: RoundState;
}
function RoundCanvases(props: RoundCanvasesProps) {    
  const winnerUUID: string = props.round.winner?.uuid ?? ''
  const nonWinnerPlayers = props.round.players.filter((player) => player.uuid !== winnerUUID);

  return (
    <>
      <Typography variant='h5' textAlign={'center'} sx={{ margin: '20px' }}>Recreate This Image:</Typography>
      <SingleCanvas user={props.round.judge} />
      {props.round.winner &&
        <>
          <Typography variant='h5' textAlign={'center'}>Winner:</Typography>
          <SingleCanvas user={props.round.winner} />
        </>
      }      
      {nonWinnerPlayers.map((player) => (
        <SingleCanvas user={player} key={player.uuid}/>
      ))}
    </>
  )
}

interface roundSelectProps {
  onRoundSelected: (roundNumber: number) => void;
  numberOfRounds: number;
  initialRound: number;
}

function RoundSelectButtons(props: roundSelectProps) {
  const [selected, setSelected] = useState(props.initialRound);

  const handleClick = (value: number) => {
    setSelected(value);
    props.onRoundSelected(value);
  };

  const buttons = Array.from({ length: props.numberOfRounds }, (_, i) => ({
    id: i + 1,
    label: `Round ${i + 1}`,
  }));

  return (
    <Container maxWidth={'sm'} sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      {buttons.map((button) => (
        <Button
          key={button.id}
          variant={selected === button.id ? 'contained' : 'outlined'}
          onClick={() => handleClick(button.id)}
        >
          {button.label}
        </Button>
      ))}
    </Container>
  );
}