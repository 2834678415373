import Frame from '../../../Common/Frame';
import { Button, Typography } from '@mui/material';
import Container from '@mui/material/Container';
import { useNavigate } from "react-router-dom"
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
export function ContactLinks() {
    return (
      <>
        <Link href="https://discord.gg/jbPFtmSP" target="_blank" rel="noopener noreferrer">
          <Typography variant="h6">Join our Discord!</Typography>
        </Link>
  
        <Link href="https://www.linkedin.com/company/bits-and-bots-fun" target="_blank" rel="noopener noreferrer">
          <Typography variant="h6">Follow us on LinkedIn!</Typography>
        </Link>
  
        <Link href="https://www.tiktok.com/@bitsandbots.fun" target="_blank" rel="noopener noreferrer">
          <Typography variant="h6">Follow us on Tiktok!</Typography>
        </Link>
      </>
    );
  }
 
export function ContactPage() {
return (
    <Box
    sx={{
     display: 'flex',
     justifyContent: 'center',
     alignItems: 'center',        
   }}
   >
    <Container maxWidth='sm'>
         <Frame title="Contact Us ">
           <Typography variant="h5">Have a question or feedback? Reach out to us!</Typography>
           <ContactLinks/>
         </Frame>
     </Container>
     </Box>
 );
}