import { Button } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState } from "react"

import { builder } from "@builder.io/react";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid'

builder.init('4c3c7feee048400593e389d81d87cda2')

interface BlogPost{
  title: string
  handle: string
  image: string
  date: string
}

export const BlogHome = () => {
  const location = useLocation()
  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([])

  useEffect(() => {
    builder.getAll('blog-article')
      .then((data) => {
        const blogPosts = data.map((post: any) => {
          return {
            title: post.data.title,
            handle: post.data.handle,
            image: post.data.image,
            date: post.data.date
          }
        })
        setBlogPosts(blogPosts)        
      })
  }, [])  

  return (
    <Grid container spacing={2}>
      {blogPosts.map((post) => (
        <Grid item xs={12} sm={6} md={4} key={post.handle}>
          <BlogPostPreview {...post} />
        </Grid>
      ))}
    </Grid>
  )
}

function BlogPostPreview(props: BlogPost) {  
  const navigate = useNavigate()
  const onClick = () => {
    const url = `/blogarticle/${props.handle}`
    navigate(url)
  }

  return (
    <>      
      <Card sx={{ maxWidth: 345 }}>
      <CardMedia
        sx={{ height: 140 }}
        image={props.image}
        title={props.title}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {props.title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          TODO: Put some secondary text here
        </Typography>
      </CardContent>
      <CardActions>        
        <Button size="small" onClick={onClick}>Learn More</Button>
      </CardActions>
    </Card>
    </>
  )
}