import { Content, fetchOneEntry } from '@builder.io/sdk-react';

import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';


export const BlogPage = () => {
  const [content, setContent] = useState<any>(null)
  const { handle } = useParams<{ handle: string }>();
  const location = useLocation();
  

  useEffect(() => {
    const url = location.pathname
    fetchOneEntry({
      model: 'blog-article',
      apiKey: '4c3c7feee048400593e389d81d87cda2',
      userAttributes: { url },
      query:{
        "data.handle": handle
      }
    }).then((content) => {
      setContent(content)      
    })

  }, [location.pathname])

  if (!content) {
    return <h1>Loading...</h1>
  }
  
  return (
    <>      
      <Content model="blog-article" content={content} apiKey='4c3c7feee048400593e389d81d87cda2'/>      
    </>
  )


}