import { Button } from "@mui/material"
import { Page } from '../SetupPage'
import { useEffect } from "react"
import { useGame } from "../../../logic/useGame"
import { useNavigate } from "react-router-dom"
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { TextFieldButton } from "../../../Common/FieldPlusButton"


export function HomePage({ onChangePage }: { onChangePage: (newPage: Page) => void }) {

 
    const navigate = useNavigate()
    
    
    
    function joinRoom(roomID: string) {
        const formattedRoomID = roomID.trim().toUpperCase();
        navigate('/game/' + formattedRoomID);
    }

    const buttonWidth = '80%'
    const maxButtonWidth = '400px'

    const buttonStyle = {
        width: buttonWidth,
        maxWidth: maxButtonWidth,
    }
 
    const game = useGame()
    useEffect(()=>{
        game.appState = null
    }, [])

    return (
        <>
    
       
        <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center',justifyContent:"center" ,gap: '20px',height:'80vh'}}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px',}}>
                <Typography variant="h3" textAlign={'center'}><strong>BITS&BOTS</strong></Typography>
                <Typography variant="body1" textAlign={'center'}>Demystifying generative ai with your friends</Typography>
            </Box>
    
            <Button variant="contained" onClick={() => onChangePage(Page.createRoom)} sx={buttonStyle}>
                Create Room
            </Button>
            
            <Typography variant="body1" textAlign={'center'}>OR</Typography>
           
            <TextFieldButton onConfirm={joinRoom} placeHolder="Room ID" buttonText="Join Room" />
            <Button variant="text" onClick={() => navigate('/howToPlay')} sx={{ ...buttonStyle, display: 'flex', justifyContent: 'space-between' }}>
                <img src="/icons/help_icon.png" alt="Help icon" style={{ height: '50px' }} />
                <Typography variant="h6" textAlign={'center'}>How to Play</Typography>
                <img src="/icons/help_icon.png" alt="Help icon" style={{ height: '50px' }} />
            </Button>
        
        </Box>        
        </>
    )
    
}
