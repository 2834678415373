import { Typography } from "@mui/material"
import Container from "@mui/material/Container"
import Button from "@mui/material/Button"
import { useState, useEffect, useRef } from "react";
import { GenerationLoadingScreen } from "../../../Common/GenerationLoadingScreen";
import { GradingPage } from "./GradingPage";
import { AnswerPage } from "./AnswerPage";
import { DialogueLine } from "../../SocialScripts/SocialScriptLogic";
import { gradeAudio, WordComparison } from "./GradingLogic";
import { AudioPlayer } from "./AudioPlayer";

import mixpanel from "mixpanel-browser";

interface Props{
    script: DialogueLine[]
    onScriptComplete: ()=>void
}

export function RepeatLangViewer(props: Props) { //TODO: Show current speed setting
    const [lineNumber, setLineNumber] = useState<number>(0);

    useEffect(()=>{
        console.log("Script changed. Resetting. All. Script: ", props.script)
        setLineNumber(0)
    }
    , [props.script])

    function onLineDone(){
        setLineNumber(lineNumber+1);        
    }

    if(lineNumber >= props.script.length){
        return (
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '20px'}}>
                <Typography variant="h3" textAlign={'center'}>You're done!</Typography>
                <Button variant="contained" onClick={props.onScriptComplete}>Go back</Button>
            </Container>
        )
    }


    return <SingleLineViewer dialogueLine={props.script[lineNumber]} onDone={onLineDone} lineNumber={lineNumber}/>    
}

interface SingleLineViewerProps {
    dialogueLine: DialogueLine;
    onDone: () => void;
    lineNumber: number;
}

export function SingleLineViewer(props: SingleLineViewerProps) {
    enum States {
        PLAYING, RECORDING, LOADING, GRADING, ANSWER
    }

    const [state, setState] = useState<States>(States.PLAYING);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const [wordsMissed, setWordsMissed] = useState<number>(0);
    const [yourSentence, setYourSentence] = useState<string>(''); 
    const [gradedSentenceJSON, setGradedSentenceJSON] = useState<WordComparison[]>([]);
    
    const actualSentence = props.dialogueLine.dialogue 

    console.log("Line: ", props.dialogueLine)

    useEffect(() => {
        console.log("Line changed. Resetting. All. Line: ", props.dialogueLine)
        setState(States.PLAYING);
        setWordsMissed(0);
        setYourSentence('');
        setGradedSentenceJSON([]);

        mixpanel.track('Repeat Language Line Updated', { lineNumber: props.lineNumber + 1, dialogue: props.dialogueLine.dialogue });

    }, [props.dialogueLine])

    function onPlayPressed() {        
        mixpanel.track('Repeat Language Play Audio', { lineNumber: props.lineNumber + 1, dialogue: props.dialogueLine.dialogue });
        if(audioRef.current){
            audioRef.current.pause();
        }
        
        const rawAudio = props.dialogueLine.audio;
        const audio = new Audio(rawAudio);
        audioRef.current = audio;
        audio.play().catch((error) => {
            console.error("Error playing audio: ", error)
        });
    }

    async function onRecordingComplete(audio: Blob) {        
        console.log(audio)
        setState(States.LOADING);  
        
        const gradedOutput = await gradeAudio(audio, actualSentence);
        console.log("Graded output: ", gradedOutput)
        mixpanel.track('Repeat Language Graded', { wordsMissed: gradedOutput.wordsMissed, wordComparisonJSON: gradedOutput.wordComparisonJSON });
        
        setWordsMissed(gradedOutput.wordsMissed);
        setGradedSentenceJSON(gradedOutput.wordComparisonJSON);
        setYourSentence(gradedOutput.transcript);

        setState(States.GRADING);
    }

    function onDoneWithAnswer() {        
        props.onDone()
    }

    if (state === States.PLAYING) {
        return <AudioPlayer 
            lineNumber={props.lineNumber}             
            onRecordingComplete={onRecordingComplete} 
            audioSrc={props.dialogueLine.audio}
        />
    }
    else if (state === States.LOADING) {
        return <GenerationLoadingScreen loadingText="Grading" loadingBarTimeInSeconds={30} />
    }
    else if (state === States.GRADING) {
        return <GradingPage onDone={() => { setState(States.ANSWER) }} onTryAgain={() => setState(States.PLAYING)} wordsMissed={wordsMissed} wordComparisonJSON={gradedSentenceJSON} />
    }
    else if (state === States.ANSWER) {
        return <AnswerPage yourSentence={yourSentence} actualSentence={actualSentence} onNext={onDoneWithAnswer} audio={props.dialogueLine.audio} />
    }
    else {
        return <Typography>ERROR: not implemented</Typography>
    }
}