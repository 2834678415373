import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import { Stack } from '@mui/material';
import { Container } from '@mui/material';


export interface CategoryCardProps {
    prompt: string;
    correctCategory: string;
    categories: string[];
    imagePath: string;
    explanation?: string;
    ttsExplanation?: string;
    categoryTTS?: string;
}

interface CategoryCardPropsWithFunction extends CategoryCardProps {
    onNextCard: () => void;
    inExamplesMode: boolean;
}

enum CardState {
    NotAnswered,
    AnsweredWrong,
    AnsweredRight,
    Example
}
export function CategoryCard(props: CategoryCardPropsWithFunction) {    
    const [cardState, setCardState] = useState(props.inExamplesMode ? CardState.Example : CardState.NotAnswered);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    const isMountedRef = useRef(true);


    useEffect(() => {   
        if (props.inExamplesMode) {
            setTimeout(()=>{
                if(isMountedRef.current) playTTS()
            }, 1000)            
            
        }

        return () => {
            isMountedRef.current = false;

            if (audioRef.current) {
                audioRef.current.pause();
                audioRef.current.currentTime = 0;
            }
        };
    }, []);

    function onCategorySelected(category: string) {
        console.log("Category selected: " + category);
        if (category === props.correctCategory) {
            setCardState(CardState.AnsweredRight);
        } else {
            setCardState(CardState.AnsweredWrong);
        }
        playTTS();
    }

    function playTTS() {        
        if (audioRef.current) {
            audioRef.current.pause();
        }

        if (props.categoryTTS && isMountedRef.current) {
            const categoryAudio = new Audio(props.categoryTTS);
            audioRef.current = categoryAudio            

            categoryAudio.play().then(() => {
                categoryAudio.onended = () => {                    
                    setTimeout(() => {
                        if (isMountedRef.current && props.ttsExplanation) {                            
                            const explanationAudio = new Audio(props.ttsExplanation);
                            audioRef.current = explanationAudio;
                            explanationAudio.play();
                        }
                }, 1000)
                };
            });
        } else if (props.ttsExplanation && isMountedRef.current) {            
            const explanationAudio = new Audio(props.ttsExplanation);
            audioRef.current = explanationAudio;
            explanationAudio.play();            
        }
    }

    return (
        <Card sx={{ maxWidth: 345 }}>

            <CardMedia
                component="img"
                image={props.imagePath}
                alt={props.prompt} />
            <CardContent>
                <CategoryCardBody cardState={cardState} prompt={props.prompt} correctCategory={props.correctCategory} explanation={props.explanation} />
            </CardContent>
            <CardActions>
                {cardState === CardState.NotAnswered ? (
                    <Container style={{ marginBottom: '20px' }}>
                        <Stack spacing={1}>
                            {props.categories.map(category => (
                                <Button key={category} variant='contained' onClick={() => { onCategorySelected(category); }}>
                                    {category}
                                </Button>
                            ))}
                        </Stack>
                    </Container>
                ) : (
                    <Button variant='contained' onClick={() => {
                        if (audioRef.current) {
                            audioRef.current.pause();
                            audioRef.current.currentTime = 0;
                        }
                        props.onNextCard();
                    }}>Next Card</Button>
                )}
            </CardActions>
        </Card>
    );
}

function CategoryCardBody(props: { cardState: CardState, prompt: string, correctCategory: string, explanation?: string }) {
    if (props.cardState === CardState.AnsweredRight) {
        return (
            <>
                <Typography variant='h5'>Correct!</Typography>
                <Typography variant='body2'>This is {props.correctCategory}</Typography>
                <Typography variant='body2'>{props.explanation}</Typography>
            </>
        )
    }
    else if (props.cardState === CardState.AnsweredWrong) {
        return (
            <>
                <Typography variant='h5'>Incorrect!</Typography>
                <Typography variant='body2'>This is {props.correctCategory}</Typography>
                <Typography variant='body2'>{props.explanation}</Typography>
            </>
        )
    }
    else if (props.cardState === CardState.NotAnswered) {
        return (
            <>
                <Typography gutterBottom variant="h5" component="div">
                    Which one is this?
                </Typography>
                <Typography variant="body2">
                    {props.prompt}
                </Typography>
            </>
        )
    }
    else if (props.cardState === CardState.Example) {
        return (
            <>
                <Typography variant='h5'>This is {props.correctCategory}</Typography>
                <Typography variant='caption'>{"prompt: " + props.prompt}</Typography>
                <Typography variant='body2'>{props.explanation}</Typography>
            </>
        )
    }
    else {
        return (
            <>
                <Typography variant='h5'>Error</Typography>
                <Typography variant='body2'>An error occurred while displaying this card</Typography>
            </>
        )
    }
}