import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useGame } from '../../../logic/useGame';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogContentText from '@mui/material/DialogContentText';

interface Props {
    onStartGame: () => void;
    amIHost: boolean;
}

function ConfirmStartDialog(props: { onStartGame: () => void }) {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        props.onStartGame();
        handleClose();
    };

    return (
        <>
            <Button variant="contained" onClick={handleOpen}>Start Game</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Start Game</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Have all players joined the room?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        No
                    </Button>
                    <Button onClick={handleConfirm} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}


interface BottomSectionProps extends Props {
    hostName: string;
}

function BottomSection(props: BottomSectionProps) {
    if (props.amIHost) {
        return <ConfirmStartDialog onStartGame={props.onStartGame} />
    }
    else {
        return <Typography variant='h6'>Waiting for {props.hostName} to Start</Typography>
    }
}
interface PlayersSectionProps {
    playersList: string[];
}

function PlayersSection(props: PlayersSectionProps) {
    return (
        <Box>
            <List>
                {props.playersList.map((player, index) => (
                    <ListItem key={index}>
                        <ListItemText primary={player} />
                    </ListItem>
                ))}
            </List>
        </Box>
    )
}

export function WaitingRoom(props: Props) {
    const game = useGame();
    const [isSnackbarOpen, setSnackbarOpen] = useState(false);
    const [playerNames, setPlayerNames] = useState<string[]>([]);

    const hostName = game?.appState?.gameState?.hostUser?.displayName ?? ' ';


    useEffect(() => {

        const newPlayerNames = []
        if (game?.appState?.gameState?.users) {
            const hostUUID = game.appState.gameState.hostUser?.uuid
            for (const player of game.appState.gameState.users) {
                if (player.uuid == game.appState.myUUID) {
                    continue
                }
                if (player.uuid == hostUUID) {
                    continue
                }

                newPlayerNames.push(player.displayName)
            }
            setPlayerNames(newPlayerNames)
        }
    }, [game!.appState?.gameState?.users])

    const copyLink = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Bits&Bots',
                text: 'Join my game of Bits&Bots!',
                url: window.location.href
            })
            return
        }
        else {
            console.error("navigator.share is undefined")
        }

        if (!navigator.clipboard || !navigator.clipboard.writeText) {
            console.error("Clipboard API not available")
            return
        }

        navigator.clipboard.writeText(window.location.href);
        setSnackbarOpen(true)
    }

    const headingText = game?.appState?.amIHost ? 'Your game lobby' : hostName + "'s game lobby";
    return (
        
            <Box
  
  >
            <Typography variant='h5'>{headingText}</Typography>
            <br />
            <Typography variant='h4'>Room Code: {game?.appState?.roomCode}</Typography>
            <Button variant="contained" onClick={copyLink}>Copy Link</Button>
            
            <PlayersSection playersList={playerNames} />
            <BottomSection onStartGame={props.onStartGame} amIHost={game?.appState?.amIHost ?? false} hostName={hostName} />
            <Snackbar open={isSnackbarOpen} autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)} message={'Link Copied!'} />
        </Box>
    )
}