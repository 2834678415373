import React, { useState, useRef, useEffect } from "react";
import { Typography, Button, Stack, Container, Divider, LinearProgress } from "@mui/material";
import { respondToAudio, AudioResponse, generateInitialResponse } from "./VoicebotLogic";
import MicIcon from '@mui/icons-material/Mic';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import mixpanel from "mixpanel-browser";
import { MessageStore } from "./MessageStore";
import { GenerationLoadingScreen } from "../../Common/GenerationLoadingScreen";
import { useAudioRecorder } from "../../logic/useAudioRecorder";
import { isVoicebotMasculine } from "./VoicebotLogic";

interface Props {
    onComplete: () => void;
    scenario: string;
}

export function VoicebotPlayer(props: Props) {
    const [isLoading, setIsLoading] = useState(true);
    const [isPlaying, setIsPlaying] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [doneWithInitial, setDoneWithInitial] = useState(false);    
    const voicebotResponse = useRef<AudioResponse | null>(null);
    const audioPlayerRef = useRef<HTMLAudioElement | null>(null);
    const useMasculineVoice = useRef<boolean>(false)

    const messageStore = useRef(new MessageStore());

    const { isRecording, startRecording, stopRecording } = useAudioRecorder(onRecordingComplete);
    const RECORDING_AUTO_STOP_SECONDS = 10;
    const recordingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const isMountedRef = useRef(true);

    useEffect(()=>{

    }, [isLoading])

    useEffect(() => {
        
        isMountedRef.current = true;
            
        async function initiateResponse() {
            useMasculineVoice.current = await isVoicebotMasculine(props.scenario)
            const result = await generateInitialResponse(props.scenario, messageStore.current, useMasculineVoice.current);
                        
            if (!isMountedRef.current) return;
            voicebotResponse.current = result;
            setIsLoading(false);
            setIsReady(true);
        }
        initiateResponse();
    
        return () => {
            isMountedRef.current = false;
            stopPlayingVoicebotAudio()
            if (recordingTimeoutRef.current) {
                clearTimeout(recordingTimeoutRef.current);
            }
    
            if (isRecording) {
                stopRecording();
            }   

        };
    }, []);
    
    function onPlayPressed() {
        if (isRecording || isLoading) {
            console.error("Cannot play while recording or loading");
            return;
        }

        if (isPlaying) {
            stopPlayingVoicebotAudio();
        } else {
            playVoicebotAudio();
        }
    }

    function onRecordingButtonPressed() {
        if (isRecording) {
            stopRecording();
            if (recordingTimeoutRef.current) {
                clearTimeout(recordingTimeoutRef.current);
            }
        } else {
            startRecording(() => {
                recordingTimeoutRef.current = setTimeout(() => {
                    stopRecording();
                    console.log("Recording Timed out");
                }, RECORDING_AUTO_STOP_SECONDS * 1000);
            });
        }
    }
    
    async function stopPlayingVoicebotAudio() {
        setIsPlaying(false);

        if (audioPlayerRef.current) {
            audioPlayerRef.current.pause();
            audioPlayerRef.current.currentTime = 0;
        }
    }

    async function playVoicebotAudio() {
        setIsPlaying(true);
        if (!voicebotResponse.current) {
            console.error("No audio to play");
            return;
        }

        const rawAudio = voicebotResponse.current.audio;
        mixpanel.track('Play Voicebot Audio', { text: voicebotResponse.current.text });

        const audio = new Audio(rawAudio);
        audioPlayerRef.current = audio;
        
        audio.play().then(() => {
            console.log('Audio playback started');
        }).catch((error) => {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
        });

        audio.onended = () => {
            stopPlayingVoicebotAudio();
        };
    }

    async function onRecordingComplete(audioBlob: Blob) {
        setIsLoading(true);
        const result = await respondToAudio(audioBlob, props.scenario, messageStore.current, useMasculineVoice.current);
        
        if (!isMountedRef.current) return;
        voicebotResponse.current = result;
        setIsLoading(false);        
        playVoicebotAudio();
    }    

    if (isLoading && !doneWithInitial) {
        return <GenerationLoadingScreen loadingBarTimeInSeconds={5} loadingText='Creating Voicebot' />;
    }

    if (isReady) {
        return (
            <Stack gap={'30px'} alignItems="center">
                <Typography variant="h4">Chatbot Ready</Typography>
                <Button variant="contained" onClick={() => { setIsReady(false); playVoicebotAudio(); setDoneWithInitial(true) }}>Continue</Button>
            </Stack>
        );
    }

    return (
        <Stack gap={'30px'} alignItems="center">
            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" onClick={props.onComplete} sx={{ maxWidth: '100px' }}>DONE</Button>
            </Container>
            <Typography variant="h6">Replay Audio</Typography>
            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    onClick={onPlayPressed}
                    sx={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                        fontSize: '24px',
                        backgroundColor: 'black',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                    disabled={isRecording || isLoading}
                >
                    {isPlaying ? <StopIcon sx={{ fontSize: 60 }} /> : <PlayArrowIcon sx={{ fontSize: 60 }} />}
                </Button>
            </Container>
            {isLoading ? (
                <LinearProgress sx={{ width: '100%', margin: '20px 0' }} />
            ) : (
                <Divider sx={{ width: '100%', borderBottomWidth: 2, margin: '20px 0', borderColor: 'black' }} />
            )}
            <Typography variant="h6">{isRecording ? 'Press to Stop Recording' : 'Press to Record'}</Typography>
            <Container sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
                    variant="contained"
                    onClick={onRecordingButtonPressed}
                    sx={{
                        width: '150px',
                        height: '150px',
                        borderRadius: '50%',
                        fontSize: '16px',
                        backgroundColor: isRecording ? 'red' : (isLoading || isPlaying) ? 'gray' : 'black',
                        '&:hover': {
                            backgroundColor: isRecording ? 'red' : (isLoading || isPlaying) ? 'gray' : 'black',
                        },
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',                        
                    }}
                    disabled={isPlaying || isLoading}
                >
                    <MicIcon sx={{ fontSize: 60, color: 'white' }} />
                </Button>
            </Container>
        </Stack>
    );    
}
