import { Typography } from "@mui/material";
import {Container} from "@mui/material";
import {Button} from "@mui/material";


interface Props{
    onBack: () => void;
}

export function CallingScreen(props: Props){
    
    
    return(
        <Container sx={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography variant="h3" textAlign={'center'} marginTop={'30px'}>Call Sent</Typography>
            <Typography variant="h6" textAlign={'center'}>The call should begin shortly.</Typography>
            <Button variant={'contained'} sx={{maxWidth: '20px', marginTop: '20px'}} onClick={props.onBack}>Back</Button>
        </Container>
    )
}