import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Container, Button, Typography } from '@mui/material';

const LoginPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Container sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Login</Typography>
      <Typography variant='body1'>Login or sign-up by clicking the button below.</Typography>
      <br />
      <Button
        variant="contained"
        color="primary"
        onClick={() => loginWithRedirect()}
      >
        Log In/Sign Up
      </Button>
    </Container>
  );
};

export default LoginPage;
