import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";


interface Props {
    yourSentence: string
    actualSentence: string
    onNext: () => void
    audio: string
}

export function AnswerPage(props: Props) {

    function onReplayAudio(){
        const audio = new Audio(props.audio);
        audio.play();
    }

    return (
        <Container maxWidth={'md'}>
            <Stack gap={'20px'} marginTop={'50px'} maxWidth={'sm'} alignItems={'center'}>
                <Typography variant='h3'>Answer</Typography>
                <Typography variant='body1'>You said: {props.yourSentence}</Typography>
                <Typography variant='body1'>The correct answer was: {props.actualSentence}</Typography>
                <Stack gap='100px'>
                <Button variant='contained' onClick={onReplayAudio}>Replay Audio</Button>
                <Button variant='contained' onClick={props.onNext}>Next Sentence</Button>
                </Stack>
            </Stack>
        </Container>
    );
}
