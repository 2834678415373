import { useEffect, useState } from "react";
import { useGame } from "../../../logic/useGame";
import { useNavigate, useParams } from "react-router-dom";
import Box from '@mui/material/Box';
import { TextFieldButton } from "../../../Common/FieldPlusButton";
import { HowToPlay } from "../../SetupPage/components/HowToPlay";
import { GameLoadingPage } from "../../../Common/GameLoadingPage";
import { ErrorPage } from "../../../Common/ErrorPage";
import { CreateImagePage } from "../../../Common/CreateImagePage";

function UsernamePage(props: { onSubmit: (username: string) => void }) {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
            <TextFieldButton onConfirm={props.onSubmit} placeHolder="Enter your name" buttonText="Confirm" />
        </Box>
    )
}

enum Page {
    howtoplay = "howtoplay",
    username = "username",
    loading = "loading",
    error = "error",
    placeholder = "placeholder",
    creatingImage = "creatingImage"
}

interface Props {
    onJoined: () => void;
}

export function JoinRoom(props: Props) {
    const [page, setPage] = useState(Page.howtoplay)
    const [username, setUsername] = useState('fakename_placeholder')
    const [waitingForServer, setWaitingForServer] = useState(false)
    const game = useGame();
    const { roomId } = useParams<{ roomId: string }>();
    const navigate = useNavigate()

    function sendJoinRequest(imageSource: string, prompt: string) {
        game.joinRoom(roomId || "missing", username, prompt, imageSource);
        setPage(Page.loading)
    }

    useEffect(() => {
        if (game.appState?.gameState != null) {
            props.onJoined()
        }
    }, [game])

    async function handlePotentialSpectator(roomId: string) {
        const shouldJoinAsSpectator = await game.shouldJoinAsSpectator(roomId)
        if(shouldJoinAsSpectator){
            game.joinAsSpectator(roomId)
        }
        else{
            setWaitingForServer(false)
        }                
    }

    useEffect(() => { 
        if (roomId) {
            setWaitingForServer(true)
            if(game.shouldRejoinRoom(roomId)){
                setWaitingForServer(false)
                game.rejoinRoom(roomId)

            }
            else{                
                handlePotentialSpectator(roomId)
            }            
        }
    }, [])

    if(waitingForServer){
        return <GameLoadingPage onError={()=>{}} timeoutInterval={-1}/>
    }

    if (page === Page.howtoplay) {
        return <HowToPlay onContinue={() => { setPage(Page.username) }} />
    }
    else if (page === Page.username) {
        return <UsernamePage onSubmit={(username) => {
            setUsername(username)            
            setPage(Page.creatingImage)
        }} />
    }
    else if (page === Page.creatingImage) {
        return <CreateImagePage onNextClicked={sendJoinRequest}/>
    }
    else if (page === Page.loading) {
        return <GameLoadingPage onError={() => setPage(Page.error)} />
    }
    else if (page === Page.error) {
        return (
            <ErrorPage onNext={() => { navigate('/') }}>
                Something went wrong while joining the room. <br /> <br /> Confirm the room code and try again.
            </ErrorPage>)
    }
    else if (page === Page.placeholder) {
        return <div>Placeholder... If you're seeing this, an error has occurred.</div>
    }
    else {
        return <ErrorPage onNext={() => { navigate('/') }} />
    }
}
