import { BackgroundImage } from "./BackgroundScreen";
import { GamePage } from "./Screens/GamePage/GamePage";
import { BlogPage } from "./Screens/Blog/BlogPage";
import { SetupPage } from "./Screens/SetupPage/SetupPage";
import { GameProvider } from "./logic/GameProvider";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HowToPlay } from "./Screens/SetupPage/components/HowToPlay";
import { ThemeProvider } from '@mui/material/styles';
import { theme } from "./AppTheme";
import { NavBar } from "./TopBar";
import { BlogHome } from "./Screens/Blog/BlogHome";
import { ContactPage } from "./Screens/SetupPage/components/Contact";
import { DeckHome } from "./Screens/Deck/DeckHome";
import { SocialScenes } from "./Screens/SocialScenes/SocialScenes";
import { VoiceCoach } from "./Screens/VoiceCoach/VoiceCoach";
import LoginPage from "./Screens/Authentication/LoginPage";

import mixpanel from "mixpanel-browser";

import ProtectedRoute from './Screens/RouteHelpers/ProtectedRoute';
import RedirectRoute from './Screens/RouteHelpers/RedirectRoute';
import { LogoutButton } from './Screens/Authentication/LogoutButton';
import { SocialScripts } from "./Screens/SocialScripts/SocialScriptsMain";
import { RepeatLanguage } from "./Screens/RepeatLanguage/RepeatLanguage";
import { useEffect } from "react";
import { Voicebot } from "./Screens/Voicebot/Voicebot";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { user, isAuthenticated } = useAuth0();


  
  useEffect(() => {
    if (isAuthenticated && user) {
      console.log("User: ", user);
      mixpanel.identify(user.sub);
      mixpanel.people.set({
        $email: user.email,
        $name: user.name
      });
    }    
  }, [isAuthenticated, user]);
  
  
  return (
    <Router>
      <ThemeProvider theme={theme}>        
          <NavBar />
          <GameProvider>
            <BackgroundImage />
            <Routes>
              <Route path='/' element={<RedirectRoute><LoginPage /></RedirectRoute>} />
              <Route path='/play' element={<SetupPage />} />
              <Route path='/coach' element={<ProtectedRoute><VoiceCoach/></ProtectedRoute>} />              
              <Route path='/deck' element={<ProtectedRoute><DeckHome /></ProtectedRoute>} />
              <Route path='/sceneMaker' element={<ProtectedRoute><SocialScenes /></ProtectedRoute>} />
              <Route path='/socialScripts' element={<ProtectedRoute><SocialScripts /></ProtectedRoute>} />
              <Route path='/socialSimulator' element={<ProtectedRoute><Voicebot /></ProtectedRoute>} />
              <Route path='/game' element={<GamePage />}>
                <Route path=':roomId' element={<GamePage />} />
                <Route path="/game/:roomId/:roundNumber" element={<GamePage />} />
              </Route>
              <Route path='/repeatEnglish' element={<ProtectedRoute><RepeatLanguage /></ProtectedRoute>} />
              <Route path='/howToPlay' element={<HowToPlay />} />
              <Route path='/contact' element={<ContactPage />} />
              <Route path='/learning' element={<BlogHome />} />
              <Route path='/blogarticle' element={<BlogPage />}>
                <Route path=':handle' element={<BlogPage />} />
              </Route>
            </Routes>
            <LogoutButton />
          </GameProvider>        
      </ThemeProvider>
    </Router>
  );
}

export default App;
