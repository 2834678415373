import Typography, { Container } from "@mui/material";
import { Button} from "@mui/material"
import { useState, useEffect } from "react"
import { useGame } from "../../../../logic/useGame";
import { HowToPlay } from "../HowToPlay";
import { TextFieldButton } from "../../../../Common/FieldPlusButton";
import { GameLoadingPage } from "../../../../Common/GameLoadingPage";
import { ErrorPage } from "../../../../Common/ErrorPage";
import Box from '@mui/material/Box';
import { CreateImagePage } from "../../../../Common/CreateImagePage";

function UsernameScreen({ onUserNameConfirmed }: { onUserNameConfirmed: (username: string) => void }) {    
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
            <TextFieldButton onConfirm={onUserNameConfirmed} placeHolder="Enter your name" buttonText="Continue"/>
        </Box>
    )
}

enum CreationState{
    HOW_TO_PLAY,    
    GETTING_USERNAME,
    CREATING_IMAGE,
    WAITING_FOR_SERVER,
    ERROR
}

interface Props{
    onRoomCreated: ()=>void
}

export function CreateRoom(props: Props) {
    let game = useGame();
    const [creationState, setCreationState] = useState(CreationState.HOW_TO_PLAY)     
    const [username, setUsername] = useState('fakename_placeholder')       
        
    
    useEffect(()=>{        
        if (game?.appState?.roomCode !== undefined)
            props.onRoomCreated()
    }, [game?.appState?.roomCode])    

    function sendCreationRequest(imageSource: string, prompt: string){
        game.createRoom(
            username,
            prompt,
            imageSource
        );
        setCreationState(CreationState.WAITING_FOR_SERVER)        
    }

    function onRetry(){
        setCreationState(CreationState.GETTING_USERNAME)
    }

    function onUserNameConfirmed(username: string){
        setUsername(username)
        setCreationState(CreationState.CREATING_IMAGE)
    }

    if (creationState === CreationState.CREATING_IMAGE){
        return <CreateImagePage onNextClicked={sendCreationRequest}/>
    }
    if (creationState === CreationState.HOW_TO_PLAY) {
        return <HowToPlay onContinue={()=>{setCreationState(CreationState.GETTING_USERNAME)}}/>
    }
    else if (creationState === CreationState.GETTING_USERNAME) {
        return <UsernameScreen onUserNameConfirmed={onUserNameConfirmed} />
    }
    else if (creationState === CreationState.WAITING_FOR_SERVER){
        return <GameLoadingPage onError={()=>{setCreationState(CreationState.ERROR)}}/>
    }
    else if (creationState === CreationState.ERROR){
        return (
            <ErrorPage onNext={onRetry}>
                Failed to create room. Please try again.
            </ErrorPage>
        )
    }    
    else {
        return <div>ERROR: Unknown CreateRoom State</div>
    }
}