import { useEffect, useState, createContext, PropsWithChildren, useRef } from "react";
import {
  createRoom as createRoomHelper, joinRoom as joinRoomHelper,
  startGame as startGameHelper, submitImage as submitImageHelper, 
  chooseWinner as chooseWinnerHelper, requestStateTransition as requestStateTransitionHelper,
  shouldJoinAsSpectator as shouldJoinAsSpectatorHelper
} from './GameServer'
import { AppState, GameState } from "./commonTypes";
import { setCookie, getCookie } from "./cookies";

export const GameContext = createContext({
  appState: null as AppState | null,
  createRoom: (username: string, imagePrompt: string, imageURL: string) => { },
  joinRoom: (roomID: string, username: string, imagePrompt: string, imageURL: string) => { },
  startGame: () => { },
  chooseWinner: (winnerUUID: string) => { },
  submitImage: (imageSource: string, imagePrompt: string) => { },
  requestStateTransition: () => { },
  shouldRejoinRoom: (roomID: string) => {if(true) return true; else return false}, //have to do this goofy construct otherwise typescript complains
  rejoinRoom: (roomID: string) => {},
  joinAsSpectator: (roomID: string) => {},
  shouldJoinAsSpectator: (roomID: string) => {return new Promise((resolve, reject) => {resolve(true)})},
});

export const GameProvider = (props: PropsWithChildren) => {
  const [appState, setAppState] = useState<AppState | null>(null);
  const gameRef = useRef(appState);

  useEffect(() => {
    gameRef.current = appState;

  }, [appState])

  const createRoom = async (username: string, imagePrompt: string, imageURL: string) => {
    try {
      const { uuid, roomCode } = await createRoomHelper(username, imagePrompt, imageURL);

      setAppState({
        roomCode: roomCode,
        amIHost: true,
        myUUID: uuid,
        inSpectatorMode: false
      });
      setCookie('roomCode', roomCode);
      setCookie('myUUID', uuid);
      setCookie('amIHost', 'true');

      startWebsocket()
    } catch (error) {
      console.error("CREATE ROOM ERROR: ", error);
    }
  };

  const shouldRejoinRoom = (roomID: string) => {
    const roomCookie = getCookie('roomCode');
    if(!roomCookie){
      return false;
    }

    if(roomCookie !== roomID){
      return false;
    }

    return true;
  }
  
  const rejoinRoom = async(roomID: string )=>{            
    const uuid = getCookie('myUUID') as string;
    const amIHost = getCookie('amIHost') === 'true';
    setAppState({
      myUUID: uuid,
      roomCode: roomID,
      amIHost: amIHost,
      inSpectatorMode: false
    });
    startWebsocket();        
  }

  const shouldJoinAsSpectator = async(roomID: string): Promise<boolean> => {
    const shouldSpectate = await shouldJoinAsSpectatorHelper(roomID);
    return shouldSpectate;
  }


  const joinAsSpectator = async(roomID: string) => {        
    setAppState({
      myUUID: 'spectator',
      roomCode: roomID,
      amIHost: false,
      inSpectatorMode: true
    });
    startWebsocket();
  }
  
  const joinRoom = async (roomID: string, username: string, imagePrompt: string, imageURL: string) => {
    try {
      const uuid = await joinRoomHelper(roomID, username, imagePrompt, imageURL)

      setAppState({
        myUUID: uuid,
        roomCode: roomID,
        amIHost: false,
        inSpectatorMode: false
      });
      setCookie('roomCode', roomID);
      setCookie('myUUID', uuid);
      setCookie('amIHost', 'false');

    }
    catch (error) {
      console.error("JOIN ROOM ERROR: ", error)
    }

    startWebsocket()
  }

  let reconnectAttempts = 0;
  const maxReconnectAttempts = 5; // Set maximum number of reconnection attempts
  const reconnectDelay = 5000;

  const startWebsocket = () => {
    const endpoint = 'wss://socket-image-lkjcclx3ma-uc.a.run.app'
    
    const ws = new WebSocket(endpoint)

    ws.addEventListener('open', () => {
      console.info('Connected to websocket');
      reconnectAttempts = 0;
      const initialMessage = { UUID: gameRef.current!.myUUID, RoomCode: gameRef.current!.roomCode }
      ws.send(JSON.stringify(initialMessage))
    })

    ws.addEventListener('message', (event) => {
      try{
        const gameState: GameState = JSON.parse(event.data)
        const stateSaysIAmhost = (gameRef.current?.myUUID && (gameState.hostUser?.uuid == gameRef.current!.myUUID)) as boolean
        const newAppState: AppState = {
          gameState: gameState,
          myUUID: gameRef.current!.myUUID,
          amIHost: gameRef.current!.amIHost || stateSaysIAmhost,
          roomCode: gameRef.current!.roomCode,
          inSpectatorMode: gameRef.current!.inSpectatorMode
        };

        setCookie('amIHost', newAppState.amIHost.toString());     
  
        if (JSON.stringify(newAppState) !== JSON.stringify(gameRef.current)) {
          setAppState(newAppState);
        }
      }
      catch(error){
        console.error("Error parsing websocket message: ", error)
      }      
    })

    ws.addEventListener('close', () => {
      console.info('Disconnected from websocket');

      if (reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          startWebsocket();
        }, reconnectDelay);
        reconnectAttempts++;
      } else {
        console.info('Max reconnection attempts reached. Stopping reconnections.');
      }
    });

  }

  const startGame = () => {
    startGameHelper(gameRef.current!.roomCode, gameRef.current!.myUUID);
  }

  const chooseWinner = (winnerUUID: string) => {
    try {
      chooseWinnerHelper(gameRef.current!.roomCode, gameRef.current!.myUUID, winnerUUID)
    }
    catch (error) {
      console.error("CHOOSE WINNER ERROR: ", error)
    }
  }

  const submitImage = (imageSource: string, imagePrompt: string) => {
    submitImageHelper(gameRef.current!.roomCode, gameRef.current!.myUUID, imageSource, imagePrompt);
  }

  const requestStateTransition = () => {
    requestStateTransitionHelper(gameRef.current!.roomCode);
  }

  return (
    <GameContext.Provider value={{
      appState: appState, createRoom, joinRoom, startGame,
      chooseWinner, submitImage, requestStateTransition,
      rejoinRoom, shouldRejoinRoom, joinAsSpectator,
      shouldJoinAsSpectator: shouldJoinAsSpectator
    }}>
      {props.children}
    </GameContext.Provider>
  );
};


