export interface User {
    uuid: string;
    displayName: string;    
    generatedImage?: GeneratedImage;
}
export interface GeneratedImage {
    prompt: string;
    url_base64: string;
}

export enum RoomStatus {
    "waitingToStart" = "waitingToStart",
    "roundInProgress" = "roundInProgress",
    "judging" = "judging",
    "gameOver" = "gameOver",
    "roundOver" = "roundOver"
}

export interface AppState{
    myUUID: string;
    amIHost: boolean;
    roomCode: string;
    inSpectatorMode: boolean;
    gameState?: GameState;
}



export interface GameState{   
    hostUser?: User; 
    roomStatus: RoomStatus;
    roundLengthInSeconds: number;    
    users: User[];
    roomCode: string;

    curRound?: RoundState;
    prevRounds?: RoundState[];
    
    curStateStartTimestamp?: number;
    judgeWaitTimeInSeconds: number;
    roundEndWaitTimeInSeconds: number;
}

export interface FirestoreSchema extends GameState{
    creationTimestamp: number;
    archived: boolean;
}

export interface RoundState{
    players: User[]
    judge: User
    winner: User | null    
}