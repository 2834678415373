import { Typography } from "@mui/material";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { WordComparison } from "./GradingLogic";
import mixpanel from "mixpanel-browser";

interface Props{
    wordsMissed: number
    wordComparisonJSON: WordComparison[]
    onTryAgain: ()=>void
    onDone: ()=>void
}

export function GradingPage(props: Props) {
    function onTryAgain(){
        mixpanel.track('Repeat Language Try Again');
        props.onTryAgain();
    }
    function onDone(){
        mixpanel.track('Repeat Language Done');
        props.onDone();
    }
    function onGiveUp(){
        mixpanel.track('Repeat Language Give Up');
        props.onDone();
    }
    

    return (
        <Container maxWidth={'md'}>
            <Stack gap={'20px'} marginTop={'50px'} maxWidth={'sm'} alignItems={'center'} marginLeft={'30px'}>
                <Typography variant="h3" textAlign={'center'}>Grading</Typography>
                <Typography variant="body1">You said: </Typography>
                <Typography variant="body1" component="div" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {props.wordComparisonJSON.map((wordComp, index) => (
                        <span 
                            key={index} 
                            style={{ color: wordComp.correct ? 'green' : 'red', marginRight: '5px' }}
                        >
                            {wordComp.word}
                        </span>
                    ))}
                </Typography>
                <Typography variant='body1'>You missed {props.wordsMissed} words.</Typography>                
                {props.wordsMissed > 0 ? 
                    <FailureSection onGiveUp={onGiveUp} onTryAgain={onTryAgain} /> : 
                    <SuccessSection onDone={onDone} />
                }
            </Stack>
        </Container>
    );
}

interface FailureSectionProps{
    onTryAgain: ()=>void
    onGiveUp: ()=>void
}   

function FailureSection(props: FailureSectionProps){
    return(
        <div style={{display: 'flex', gap: '100px', flexDirection: 'column'}}>
            <Button variant='contained' onClick={props.onTryAgain}>Try Again</Button>
            <Button variant='contained' onClick={props.onGiveUp}>Give Up</Button>
        </div>
    )
}

interface SuccessSectionProps {
    onDone: ()=>void
}

function SuccessSection(props: SuccessSectionProps){
    return(
        <>
            <Typography variant='body1'>Congrats! You said all the words in the sentence.</Typography>
            <Button variant='contained' onClick={props.onDone}>Next</Button>
        </>
    )
}
