
export type MessageRole = 'user' | 'system' | 'assistant';

export class MessageStore {
    private messages: { role: MessageRole, content: string }[] = [];

    addMessage(role: MessageRole, content: string) {
        this.messages.push({ role, content } as const);
    }

    getMessages() {
        return this.messages;
    }
}
