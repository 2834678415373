import { Typography, TextField, Button, Stack, Container } from "@mui/material";
import { useState } from "react";
import { GenerationLoadingScreen } from "../../Common/GenerationLoadingScreen";
import { generateDialogue, DialogueLine } from "./SocialScriptLogic";
import { ScriptPlayer } from "./ScriptPlayer";
import mixpanel from "mixpanel-browser";

export enum Difficulty {
    EASY = "Simple",
    MEDIUM = "Medium",
    HARD = "Advanced"
}

interface ScriptGeneratorProps {
    onGenerate: (dialogue: string, difficulty: Difficulty) => void;
    initialScenario: string
}

function ScriptGenerator(props: ScriptGeneratorProps) {
    const [dialogue, setDialogue] = useState<string>(props.initialScenario);
    const [difficulty, setDifficulty] = useState<Difficulty>(Difficulty.EASY);

    return (
        <Container>
            <Stack gap={'20px'} sx={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
                <Typography variant="h4">Social Scripts</Typography>
                <Typography variant="body1">
                    Describe a social situation, select the conversation complexity, then click generate. The AI will write a script and voice act it for you.<br/><br/>
                    You can try functional conversations, like buying ice cream, or social conversations, like seeing a friend after a long time.
                </Typography>                    

                <TextField
                    id="outlined-basic"
                    label="Write a scenario"
                    variant="outlined"
                    value={dialogue}
                    onChange={(e) => setDialogue(e.target.value)}
                    sx={{ backgroundColor: 'white' }}
                    fullWidth={true}
                    placeholder='An 11 year old boy named Steve tells his Mom about his day at school. He discusses his experiment in science class.'
                    multiline={true}
                    minRows={3}
                />
                <Typography variant='h6'>Complexity</Typography>
                <Stack direction="row" spacing={0}>
                    
                    <Button variant={difficulty === Difficulty.EASY ? "contained" : "outlined"} onClick={() => setDifficulty(Difficulty.EASY)}>{Difficulty.EASY}</Button>
                    <Button variant={difficulty === Difficulty.MEDIUM ? "contained" : "outlined"} onClick={() => setDifficulty(Difficulty.MEDIUM)}>{Difficulty.MEDIUM}</Button>
                    <Button variant={difficulty === Difficulty.HARD ? "contained" : "outlined"} onClick={() => setDifficulty(Difficulty.HARD)}>{Difficulty.HARD}</Button>
                </Stack>

                <Button variant="contained" sx={{marginTop: '50px'}}
                    disabled={dialogue === "" || difficulty === null}
                    onClick={() => props.onGenerate(dialogue, difficulty!)}>Generate</Button>
            </Stack>
        </Container>
    );
}

export function SocialScripts() {
    enum ScriptState {
        HOME, LOADING, SHOW_SCRIPT
    }

    const [scriptState, setScriptState] = useState<ScriptState>(ScriptState.HOME);
    const [allDialogueLines, setAllDialogueLines] = useState<DialogueLine[]>([]);
    const [scenarioText, setScenarioText] = useState<string>("");

    function onGenerate(scenario: string, difficulty: Difficulty) {
        console.log(scenario);
        setScenarioText(scenario);
        setScriptState(ScriptState.LOADING);
        generateDialogue(scenario, difficulty).then((response) => { 
            console.log("Response: ", response);
            setAllDialogueLines(response);
            setScriptState(ScriptState.SHOW_SCRIPT);
            mixpanel.track('Social Script Generated', { scenario: scenario, dialogue: response, difficulty: difficulty });
        });
    }

    function onBack(){
        setScriptState(ScriptState.HOME);        
        setAllDialogueLines([]);
    }

    if (scriptState === ScriptState.HOME) return <ScriptGenerator onGenerate={onGenerate} initialScenario={scenarioText}/>
    if (scriptState === ScriptState.LOADING) return <GenerationLoadingScreen loadingBarTimeInSeconds={20} loadingText='Generating Dialogue' />
    if (scriptState === ScriptState.SHOW_SCRIPT) return <ScriptPlayer dialogue={allDialogueLines} scenario={scenarioText} onBack={onBack}/>

    return <Typography>ERROR. SampleDialogue. Not implemented</Typography>;
}
