import { Typography, Button, Stack, Container } from "@mui/material";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import StopIcon from '@mui/icons-material/Stop';
import { useState, useRef, useEffect } from "react";
import { DialogueLine } from "./SocialScriptLogic";
import mixpanel from "mixpanel-browser";

interface Props {
    dialogue: DialogueLine[];
    scenario: string;
    onBack: () => void;
}

export function ScriptPlayer(props: Props) {
    const [currentLine, setCurrentLine] = useState<number | null>(null);    
    const [isPaused, setIsPaused] = useState(true);
    const isPlayingAllRef = useRef(false);
    const audioRef = useRef<HTMLAudioElement | null>(null);
    
    useEffect(() => {
        return () => {
            handleStop();
        };
    }, []);

    const playAllDialogue = async () => {
        mixpanel.track('Play All Dialogue');
        handleStop()
        isPlayingAllRef.current = true;
        setIsPaused(false)
        for (let i = 0; i < props.dialogue.length; i++) {
            if (!isPlayingAllRef.current) {
                break;
            }
            await playLine(i);
        }
        isPlayingAllRef.current = false;
        setIsPaused(true)
    };

    const playLine = async (index: number) => {
        setCurrentLine(index);
        const rawAudio = props.dialogue[index].audio;

        const dialogueText = props.dialogue[index].dialogue;
        mixpanel.track('Play Single Dialogue Line', { dialogue: dialogueText });
        
        const audio = new Audio(rawAudio);        
        audioRef.current = audio;
        await audio.play();
        
        await new Promise((resolve) => {
            audio.onended = () => {
                setCurrentLine(null);
                resolve(true);
            };
            audio.play().catch((error) => {
                console.error('Error playing audio:', error);
                resolve(false);
            });
        });
    }

    const handlePlayPause = () => {
        if(!isPlayingAllRef.current){
            playAllDialogue()
        }
        else{
            if(isPaused){
                audioRef.current?.play()
                setIsPaused(false)
            }
            else{
                audioRef.current?.pause()
                setIsPaused(true)
            }            
        }        
    };

    const handleStop = async () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;            
        }
        setCurrentLine(null);
        isPlayingAllRef.current = false;
        setIsPaused(true)
    };

    const handleBoxClick = async (index: number) => {
        await handleStop()
        await playLine(index)                        
    };

    function onBack(){
        mixpanel.track('Script Player Back');
        handleStop();
        props.onBack();
    }

    return (
        <Container maxWidth='xl' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack>
                <Stack direction="row" spacing={2} sx={{ marginBottom: '20px' }}>
                    <Button variant="contained" onClick={handlePlayPause}>
                        {isPaused ? <PlayArrowIcon /> : <PauseIcon />}
                    </Button>
                    <Button variant="contained" onClick={handleStop}>
                        <StopIcon />
                    </Button>
                    <Button variant='contained' onClick={onBack}>Back</Button>
                </Stack>
                <Typography>Scenario: {props.scenario}</Typography>
                {props.dialogue.map((line, index) => (
                    <Stack
                        key={index}
                        sx={{
                            backgroundColor: currentLine === index ? 'lightgray' : 'white',
                            padding: '10px',
                            border: '1px solid black',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleBoxClick(index)}
                    >
                        <Typography variant="h6" textAlign={'center'}>{line.speakerName}</Typography>
                        <Typography variant="body1" textAlign={'center'}>{line.dialogue}</Typography>
                    </Stack>
                ))}
            </Stack>
        </Container>
    );
}
