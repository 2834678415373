import { HomePage } from "./components/HomePage";
import { CreateRoom } from "./components/CreateRoom/CreateRoom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGame } from "../../logic/useGame";

export enum Page {
    home = "home",
    createRoom = "createRoom",    
    placeholder = "placeholder"
}

export function SetupPage(){
    const [page, setPage] = useState(Page.home) 
    const navigate = useNavigate()  
    const game = useGame()    

    function changePage(newPage: Page){
        setPage(newPage)
    }

    function roomCreated(){        
        navigate('/game/' + game?.appState?.roomCode)        
    }    

    if (page === Page.home){
        return <HomePage onChangePage={changePage}/>
    }    
    else if (page == Page.createRoom){
        return <CreateRoom onRoomCreated={roomCreated}/>
    }    
    else if (page == Page.placeholder){
        return <div>Setup placeholder... If you're seeing this, an error has occurred.</div>
    }
    else {
        return <div>ERROR</div>
    }
}