//Returns UUID + Room Code
const USE_LOCAL_ENDPOINT = false;
const USE_STAGING_ENDPOINT = true;

const LOCAL_ENDPOINT_HEADING = 'http://127.0.0.1:5001'

export async function createRoom(username: string, imagePrompt: string, imageURLBase64: string) {    

    const queryParams = new URLSearchParams({
        username,
        prompt: imagePrompt,
        imageURLBase64: imageURLBase64,
        roundLength: "60"
    }).toString();

    let prodEndpoint = `https://createroom-lkjcclx3ma-uc.a.run.app?${queryParams}`;
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('createroom', 'createroom-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/createRoom?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`Error: ${response}`);
    }    
    
    const data = await response.json();    
    return {uuid: data.userID, roomCode: data.roomID}
}

export async function requestStateTransition(roomID: string){
    const queryParams = new URLSearchParams({
        roomID
    }).toString();
    
    let prodEndpoint = `https://requeststatetransition-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('requeststatetransition', 'requeststatetransition-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/requestStateTransition?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }
}

export async function joinRoom(roomID: string, username: string, imagePrompt: string, imageURL: string){
    const queryParams = new URLSearchParams({
        username,
        roomID,
        prompt: imagePrompt,
        imageURLBase64: imageURL,
    }).toString();

    let prodEndpoint = `https://joinroom-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('joinroom', 'joinroom-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/joinRoom?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }

    const data = await response.json();
    return data.userID
}

export async function startGame(roomID: string, userID: string){    
    const queryParams = new URLSearchParams({
        roomID,
        userID
    }).toString();

    let prodEndpoint = `https://startgame-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('startgame', 'startgame-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/startGame?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }
}

export async function submitImage(roomID: string, userID: string, imageURL: string, imagePrompt: string){    
    const queryParams = new URLSearchParams({
        roomID,
        userID,
        imageURLBase64: imageURL,
        prompt: imagePrompt
    }).toString();

    let prodEndpoint = `https://submitimage-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('submitimage', 'submitimage-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/submitImage?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }
}

export async function chooseWinner(roomID: string, userID: string, winnerUUID: string){    
    const queryParams = new URLSearchParams({
        roomID,
        userID,
        winnerID: winnerUUID
    }).toString();

    let prodEndpoint = `https://choosewinner-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('choosewinner', 'choosewinner-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/chooseWinner?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }
}

export async function shouldJoinAsSpectator(roomID: string){
    const queryParams = new URLSearchParams({
        roomID
    }).toString();

    let prodEndpoint = `https://shouldjoinasspectator-lkjcclx3ma-uc.a.run.app?${queryParams}`
    if(USE_STAGING_ENDPOINT) {
        prodEndpoint = prodEndpoint.replace('shouldjoinasspectator', 'shouldjoinasspectator-staging')
    }
    const localEndpoint =  `${LOCAL_ENDPOINT_HEADING}/bitsandbots/us-central1/shouldJoinAsSpectator?${queryParams}`
    const endpoint = USE_LOCAL_ENDPOINT ? localEndpoint : prodEndpoint

    const response = await fetch(endpoint)
    if(!response.ok){
        throw new Error(`Error: ${response}`)
    }

    const data = await response.json();
    return data.shouldJoinAsSpectator as boolean
}