import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useState, useEffect } from 'react';


export enum CANVAS_STATE {
  INITIAL,
  GENERATING,
  ERROR,
  SUCCESS
}

interface CanvasProps {
  state: CANVAS_STATE;

  imageSource?: string;
  placeholderText?: string;
  errorText?: string;  
  imageIsNotEncoded?: boolean;
  fixedSize?: number;
  bigCanvas?: boolean;
}

CanvasDisplay.defaultProps = {
  placeHolderText: 'Get Creative!',
  errorText: 'An error occurred while generating the image.'
}


function LoadingAnimation() {
  const [loadingText, setLoadingText] = useState('Loading');

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingText((prevText) => {
        if (prevText === 'Loading...') {
          return 'Loading.';
        } else {
          return prevText + '.';
        }
      });
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <Typography variant='h6'>{loadingText}</Typography>;
}

export function CanvasDisplay({ 
  state,
  imageSource, 
  placeholderText = 'Get Creative!', 
  errorText = 'An error occurred while generating the image.', 
  imageIsNotEncoded = false,
  bigCanvas = false
}: CanvasProps) {

  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Convert from base64
  const imageSourceUnencoded = imageIsNotEncoded ? imageSource : atob(imageSource || '');
  
  return (
    <Box
      sx={{
        minWidth: bigCanvas ? screenSize.width * 0.8 : '128px',
        minHeight: bigCanvas ? screenSize.height * 0.6 : '256px',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',        
      }}
    >
      {state === CANVAS_STATE.INITIAL && <Typography textAlign="center">{placeholderText ?? "no default"}</Typography>}
      {state === CANVAS_STATE.GENERATING && <LoadingAnimation />}
      {state === CANVAS_STATE.ERROR && (
        <Typography textAlign="center">{errorText}</Typography>
      )}
      {state === CANVAS_STATE.SUCCESS && (
        <img 
        src={imageSourceUnencoded} 
        alt="An AI generated image created with a secret prompt" 
        style={{maxWidth: bigCanvas ? '100%' : '256px', maxHeight: bigCanvas ? '512px' : '256px'}}
    />
      )}
    </Box>
  )
}


export default CanvasDisplay;
