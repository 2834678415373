import { Typography } from "@mui/material";
import { TextField } from "@mui/material";
import { Button } from "@mui/material";
import { useState } from "react";
import { Stack, Container } from "@mui/material";
import { GenerationLoadingScreen } from "../../Common/GenerationLoadingScreen";
import { RepeatLangViewer } from "./Viewer/ViewerMain";
import { generateDialogue, DialogueLine } from "../SocialScripts/SocialScriptLogic";
import mixpanel from "mixpanel-browser";

export function RepeatLanguage() {        
    enum States{
        CREATE, LOADING, VIEW
    }

    const [state, setState] = useState<States>(States.CREATE);
    const [scenarioScript, setScenarioScript] = useState<DialogueLine[]>([]);

    async function onGenerate(prompt: string) {
        console.log(prompt);
        setState(States.LOADING);
        const script = await generateDialogue(prompt);
        setScenarioScript(script);
        mixpanel.track('Repeat Language Generated', { scenario: prompt, dialogue: script });
        console.log("Script: ", script)
        setState(States.VIEW);                
    }

    function onScriptComplete(){        
        setState(States.CREATE);
    }

    if(state === States.CREATE){
        return <CreationPage onGenerate={onGenerate}/>
    }
    if(state === States.LOADING){
        return <GenerationLoadingScreen loadingText="Loading" loadingBarTimeInSeconds={30}/>
    }
    if(state === States.VIEW){
        return <RepeatLangViewer script={scenarioScript} onScriptComplete={onScriptComplete}/>
    }
    return <Typography>ERROR: not implemented</Typography>
}


function CreationPage(props: {onGenerate: (prompt: string) => void}) {
    const [prompt, setPrompt] = useState<string>("");
            

    return (
        <Container>
            <Stack gap={'20px'} sx={{ display: 'flex', alignItems: 'center', margin: '20px' }}>
                <Typography variant="h4">English Comprehension Trainer</Typography>
                <>
                    <Typography variant="body1">Describe a conversational topic, then click next. The app will play a conversation for you. Your job is to repeat the words that you hear.<br/><br/>You should make the conversation something you do commonly in your life, like discussing gardening with a friend.</Typography>                    
                </>

                <TextField
                    id="outlined-basic"
                    label="Write a scenario"
                    variant="outlined"
                    value={prompt} // Bind state variable to TextField
                    onChange={(e) => setPrompt(e.target.value)} // Update state on change
                    sx={{ backgroundColor: 'white' }} // Set background color to white
                    fullWidth={true}
                    placeholder='Grandmother discusses dinner plans with her grandson' multiline={true} minRows={3}
                />
                <Button variant="contained"
                    disabled={prompt === ""}
                    onClick={() => props.onGenerate(prompt)}>Next</Button>
            </Stack>
        </Container>
    )
}