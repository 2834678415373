import { Typography, Container, Divider, TextField, Stack, Button, Switch, FormControlLabel, IconButton, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useState } from "react";
import { makeCall } from "./serverLogic";
import { useAuth0 } from "@auth0/auth0-react";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import mixpanel from "mixpanel-browser";
import YouTubeIcon from '@mui/icons-material/YouTube';

interface TaskFieldProps {
    index: number;
    task: string;
    onDelete: (index: number) => void;
    onEdit: (index: number, newTask: string) => void;
}


function TaskField(props: TaskFieldProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedTask, setEditedTask] = useState(props.task);

    const handleSave = () => {
        if (editedTask.trim() !== "") {
            props.onEdit(props.index, editedTask);
            setIsEditing(false);
        }
    };

    return (
        <Stack direction={'row'} spacing={2}>
            <TextField
                value={isEditing ? editedTask : `${props.index + 1}) ${props.task}`}
                onChange={(event) => setEditedTask(event.target.value)}
                multiline // Enables text wrapping
                sx={{
                    backgroundColor: 'white',
                    width: '100%',
                    '& .Mui-disabled': {
                        WebkitTextFillColor: 'black', // For WebKit browsers
                        color: 'black', // For other browsers
                    },
                }}
                disabled={!isEditing}
                InputProps={{
                    sx: {
                        color: 'black',
                        '&.Mui-disabled': {
                            WebkitTextFillColor: 'black',
                        },
                    },
                }}
            />
            {isEditing ? (
                <IconButton onClick={handleSave}>
                    <SaveIcon />
                </IconButton>
            ) : (
                <IconButton onClick={() => setIsEditing(true)}>
                    <EditIcon />
                </IconButton>
            )}
            <IconButton onClick={() => props.onDelete(props.index)}>
                <DeleteIcon />
            </IconButton>
        </Stack>
    );
}

interface Props {
    onCall: () => void;
    onSave: () => void;
    onGoToSavedRoutines: () => void;
    routineName: string;
    setRoutineName: (name: string) => void;
    personName: string;
    setPersonName: (name: string) => void;
    phoneNumber: string;
    setPhoneNumber: (number: string) => void;
    createdTasks: string[];
    setCreatedTasks: (tasks: string[]) => void;
    shouldSendEmail: boolean;
    setShouldSendEmail: (value: boolean) => void;
    editingRoutineId?: string;
    onCancelEditing?: () => void;
    requireStrictConfirmation: boolean;
    setRequireStrictConfirmation: (value: boolean) => void;
    speakInSimpleSentences: boolean;
    setSpeakInSimpleSentences: (value: boolean) => void;
}

export function RoutineBuilder(props: Props) {
    const [newTask, setNewTask] = useState<string>("");    

    const user = useAuth0()
    const email = user.user?.email || "";

    const addTask = () => {
        if (newTask.trim() !== "") {
            const taskToAdd = newTask.trim().replaceAll(',', '')
            props.setCreatedTasks([...props.createdTasks, taskToAdd]);
            setNewTask("");
        }
    };

    const editTask = (index: number, newTask: string) => {
        const updatedTasks = props.createdTasks.map((task, i) =>
            i === index ? newTask : task
        );
        props.setCreatedTasks(updatedTasks);
    };

    const deleteTask = (index: number) => {
        const newTaskFields = props.createdTasks.filter((_, i) => i !== index);
        props.setCreatedTasks(newTaskFields);
    };

    const isCallButtonDisabled = () => {
        return (
            props.routineName.trim() === "" ||
            props.personName.trim() === "" ||
            props.phoneNumber.trim() === "" ||
            props.phoneNumber.trim().length < 10 ||
            props.createdTasks.length === 0
        );
    };

    function onCallClicked() {
        let parsedPhoneNumber = props.phoneNumber.trim();

        parsedPhoneNumber = parsedPhoneNumber.replace(/[^0-9+]/g, '');

        if (parsedPhoneNumber.length === 10 && !parsedPhoneNumber.startsWith('+')) {
            parsedPhoneNumber = '+1' + parsedPhoneNumber;
        }

        if (parsedPhoneNumber.startsWith('+') && parsedPhoneNumber.length >= 11) {
            console.log(`Calling ${props.personName} at ${parsedPhoneNumber} with routine: ${props.routineName}`);
            mixpanel.track("CallFromRoutineBuilder", { routineName: props.routineName });
            makeCall({
                numberToCall: parsedPhoneNumber,
                taskList: props.createdTasks,
                personName: props.personName,
                routineName: props.routineName,
                emailToNotify: props.shouldSendEmail ? email : "",
                shouldSendEmail: props.shouldSendEmail,
                requireStrictConfirmation: props.requireStrictConfirmation,
                speakInSimpleSentences: props.speakInSimpleSentences
            });
            props.onCall();
        } else {
            console.error('Invalid phone number format');
        }
    }

    return (
        <Container>
            <Typography variant="h3" textAlign={'center'}>AI Coach</Typography>
            <Typography variant="h6" textAlign={'center'} marginTop={'20px'}>
                This is an AI Chatbot that will guide someone through a routine (like doing laundry or their bedtime).
                It will call them and make sure they complete all of these steps.
            </Typography>

            
            <Stack direction="row" justifyContent="center" marginTop="20px">
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<YouTubeIcon />}
                    onClick={() => window.open('https://youtu.be/EoHQiV4TXTE', '_blank')}
                    sx={{ backgroundColor: '#FF0000' }}
                >
                    Watch Tutorial Video
                </Button>
            </Stack>


            <Typography variant="h4" textAlign={'center'} marginTop={'20px'} marginBottom={'20px'}>Task List</Typography>
            {props.createdTasks.map((task, index) => (
                <TaskField
                    onEdit={editTask}
                    key={index}
                    index={index}
                    task={task}
                    onDelete={deleteTask}
                />
            ))}
            <Stack sx={{ marginTop: '40px' }} direction={'row'} alignItems="center">
                <Typography sx={{ minWidth: '150px' }}>Enter New Task:</Typography>
                <TextField
                    placeholder="Brush your teeth"
                    value={newTask}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setNewTask(event.target.value);
                    }}
                    sx={{ backgroundColor: 'white', width: '100%' }}
                />
                <Button onClick={addTask} variant='contained'>Add</Button>
            </Stack>

            <Divider sx={{ width: '100%', borderBottomWidth: 1, margin: '20px 0', borderColor: 'black' }} />

            <Stack spacing={2}>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Routine Name:</Typography>
                    <TextField
                        placeholder="Bedtime Routine"
                        value={props.routineName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setRoutineName(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Person's Name:</Typography>
                    <TextField
                        placeholder="Jonathan"
                        value={props.personName}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setPersonName(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center">
                    <Typography sx={{ minWidth: '150px' }}>Phone Number:</Typography>
                    <TextField
                        placeholder="+11234567890"
                        value={props.phoneNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setPhoneNumber(event.target.value)}
                        sx={{ backgroundColor: 'white', flexGrow: 1 }}
                    />
                </Stack>
                <FormControlLabel
                    control={
                        <Switch
                            checked={props.shouldSendEmail}
                            onChange={(event) => {
                                props.setShouldSendEmail(event.target.checked);
                            }}
                        />
                    }
                    label="Send Email Report When Complete"
                    sx={{ marginTop: '20px' }}
                />

                <Accordion sx={{ marginTop: '20px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="advanced-settings-content"
                        id="advanced-settings-header"
                    >
                        <Typography variant="h5" >Advanced Settings</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.requireStrictConfirmation}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setRequireStrictConfirmation(event.target.checked)}
                                />
                            }
                            label="Require Strict Confirmation"
                        />
                        <Typography sx={{ marginTop: '10px' }}>
                            If enabled, the assistant will require the user to clearly say "Yes, I am done"
                            before it will move on to the next task. This can be helpful for users with certain communication
                            challenges.
                        </Typography>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={props.speakInSimpleSentences}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setSpeakInSimpleSentences(event.target.checked)}
                                />
                            }
                            label="Speak in Simple Sentences"
                            sx={{ marginTop: '20px' }}
                        />
                        <Typography sx={{ marginTop: '10px' }}>
                            If enabled, the assistant will speak in simple sentences to make it easier for the user to understand.
                        </Typography>
                    </AccordionDetails>
                </Accordion>

                <Stack gap={'20px'} alignItems={'center'}>
                    <Button
                        variant="contained"
                        disabled={isCallButtonDisabled()}
                        onClick={onCallClicked}
                        sx={{ width: '100%' }}
                    >
                        Call
                    </Button>
                    <Button variant="contained"
                        onClick={props.onSave}
                        sx={{ width: '100%' }}
                        disabled={isCallButtonDisabled()}
                    >
                        {props.editingRoutineId ? 'Next' : 'Save'}
                    </Button>
                    {props.editingRoutineId && (
                        <Button variant="contained"
                            onClick={props.onCancelEditing}
                            sx={{ width: '100%' }}
                        >Cancel Editing</Button>
                    )
                    }
                    <Button variant="contained"
                        onClick={props.onGoToSavedRoutines}
                        sx={{ width: '100%' }}
                    >
                        Saved Routines
                    </Button>
                </Stack>

            </Stack>
        </Container>
    );
}
