
import { useEffect, useState } from 'react';
import { WaitingRoom } from './components/WaitingRoom';
import { PlayingPage } from './components/PlayingPage';
import { useGame } from '../../logic/useGame';
import { RoomStatus } from '../../logic/commonTypes';
import { JoinRoom } from './components/JoinRoom';
import { GameOverPage } from './components/GameOverPage';

enum Page {
    JOINING,
    WAITING,    
    PLAYING,
    GAME_OVER
}

export function GamePage() {
    const [gamePage, setGamePage] = useState(Page.WAITING);
    const game = useGame();

    const handleStartGame = () => {        
        game.startGame();               
    };

    useEffect(()=>{        
        if(game.appState?.gameState == null){
            if(!game.appState?.amIHost) setGamePage(Page.JOINING)                            
            return
        }
        const roomStatus = game.appState?.gameState?.roomStatus
        if (!roomStatus) return

        if(roomStatus === RoomStatus.gameOver){
            setGamePage(Page.GAME_OVER)
        }
        else if(roomStatus != RoomStatus.waitingToStart){
            setGamePage(Page.PLAYING)
        }
    }, [game.appState?.gameState])
    

    const renderScreen = () => {
        switch (gamePage) {
            case Page.JOINING:
                return <JoinRoom onJoined={()=>{setGamePage(Page.WAITING)}}/>;
            case Page.WAITING:
                return <WaitingRoom onStartGame={handleStartGame} amIHost={game.appState?.amIHost || false} />;            
            case Page.PLAYING:
                return <PlayingPage />
            case Page.GAME_OVER:
                return <GameOverPage />

            default:
                return <h1>ERROR - Should not reach this state</h1>
        }
    };

    return <div>{renderScreen()}</div>;
};

export default GamePage;
