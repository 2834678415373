import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';

interface Props {
    onError: () => void;
    timeoutInterval: number;
}

GameLoadingPage.defaultProps = {
    timeoutInterval: 10000,
}


export function GameLoadingPage(props: Props) {
    const [loadingText, setLoadingText] = useState("Loading...");

    useEffect(() => {
        const timer = setInterval(() => {
            setLoadingText((prevText) => {
                if (prevText === "Loading.") {
                    return "Loading..";
                } else if (prevText === "Loading..") {
                    return "Loading...";
                } else {
                    return "Loading.";
                }
            });
        }, 500);

        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        if(props.timeoutInterval < 0) return;
        const errorTimer = setTimeout(() => {
            props.onError();
        }, props.timeoutInterval);

        return () => clearTimeout(errorTimer);
    }, []);

    return (
        <Typography variant="h3" textAlign='center'>
            {loadingText}
        </Typography>
    );
}
