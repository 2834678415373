import { useState, useEffect } from 'react';
import { Stack, Typography, Box, LinearProgress } from '@mui/material';


interface Props{
  loadingBarTimeInSeconds: number;
  loadingText: string;
}

export function GenerationLoadingScreen(props: Props) {
  const [dots, setDots] = useState('');
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const dotsInterval = setInterval(() => {
      setDots(dots => dots.length < 3 ? dots + '.' : '');
    }, 500);

    const progressInterval = setInterval(() => {
      setProgress(oldProgress => {
        if (oldProgress >= 100) {
          clearInterval(progressInterval);
          return 100;
        }
        return Math.min(oldProgress + (100 / (props.loadingBarTimeInSeconds * 2)), 100);
      });
    }, 500);

    return () => {
      clearInterval(dotsInterval);
      clearInterval(progressInterval);
    };
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      marginTop={10}
    >
      <Stack spacing={2} alignItems="center" width="80%">
        <Typography variant="h6">{props.loadingText}{dots}</Typography>
        <Box width="100%">
          <LinearProgress variant="determinate" value={progress} />
        </Box>
      </Stack>
    </Box>
  );
}
