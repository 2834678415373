import { GenerativeCanvas } from "./Canvas/GenerativeCanvas"
import Box from '@mui/material/Box';

interface Props{
    onNextClicked: (imagePath: string, imagePrompt: string)=>void
}   

export function CreateImagePage(props: Props){

    return(
        <Box>
            <GenerativeCanvas onNextClicked={props.onNextClicked}/>
        </Box>
    )
}